<template>
  <div class="header-background container-fluid"
       v-if="banner && banner.images != null && banner.images.length > 0"
       :style="`background-image: url('${this.source(banner.images[0])}');`">
    <div class="container">
      <div class="hero-text"
           :class="{'float-left': banner.text_position == 'top_left',
                      'float-right': banner.text_position == 'top_right',
                      'float-left bottom': banner.text_position == 'bottom_left',
                      'float-right bottom': banner.text_position == 'bottom_right',
                      'center': banner.text_position == 'center'}"
           v-if="banner.title && banner.text">
        <b class="big" v-if="banner.title">{{ banner.title }}</b>
        <p v-if="banner.text" v-html="banner.text"></p>
        <a v-if="banner.button_text"
           :href="banner.button_link"
           class="btn btn-primary">
          {{ banner.button_text }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    'banner': Object,
  },
  methods: {
    source: function(image) {
      return window.location.protocol + '//' + window.host() + '/' + image.data_dir + '/' +
          image.identifier.replace('.jpg', `.2048x2048.fit.q85.jpg`)
    },
  },
}
</script>