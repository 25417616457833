<template>
  <div class="account container">
    <div class="row">
      <div class="col">
        <AccountNavigation :active="'wishlist'"/>
        <div class="card mb-4">
          <div class="card-header">
            <h3>{{ $t('wishlist') }}: {{ user.username }}</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col">
                <div class="row mt-4">
                  <div class="col-lg-3 col-md-4"
                       v-for="item in this.$store.getters.wishlist" :key="item.id">
                    <Product :product="item"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountNavigation from '@/components/account/AccountNavigation'
import WishlistRepository from '@/repository/WishlistRepository'
import Product from '@/components/Product'

export default {
  name: 'Wishlist',
  components: {
    Product,
    AccountNavigation,
  },
  methods: {},
  mounted() {
    WishlistRepository.getInstance().getUsersWishlist()
  },
}
</script>

<style lang="scss" scoped>
.account {
  padding-top: 60px;
  padding-bottom: 60px;
}
</style>
