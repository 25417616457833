import store from '../store'
import Vue from 'vue'
import * as _ from 'lodash'

Vue.mixin({
  methods: {
    getPagesFromModule(module) {
      let pages = _.filter(store.getters.pages,
        (it) => it.module === module && it.lang === this.currentLocale)
      return _.sortBy(pages, ['sort'])
    }
  },
})