<template>
  <div class="progress" :class="{transparent: !isLoading}">
    <div class="progress-bar progress-bar-striped progress-bar-animated"
         role="progressbar"
         style="width: 100%"></div>
  </div>
</template>

<script>
export default {
  name: 'PageProgressBar',
}
</script>

<style lang="scss" scoped>
.progress {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 8px;
  transition: 150ms;
  z-index: 10000;

  &.transparent {
    transition: 500ms;
    opacity: 0;
  }
}
</style>