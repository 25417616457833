<template>
  <img :src="source" :alt="alt">
</template>

<script>

export default {
  name: 'BackendImage',
  props: {
    'identifier': String,
    'alt': String,
    'size': Number,
  },
  computed: {
    source: function() {
      if (this.identifier != null) {
        return window.location.protocol + '//' + window.host() + '/data/' + this.identifier
            .replace('.jpg', `.${this.size}x${this.size}.fit.q85.jpg`)
      } else {
        return '/assets/product-placeholder.jpg'
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>