<template>
  <div class="products-filter">
    <div class="manufacturer d-none"> <!-- hidden for now -->
      <div class="dropdown simple">
        <button class="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
          {{ $t('manufacturers') }}
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a v-for="manufacturer in manufacturers"
             :key="manufacturer.id"
             v-on:click="manufacturerSelected(manufacturer)"
             :class="{ active: manufacturer.id == manufacturerId }"
             class="dropdown-item">
            {{ manufacturer.title }}
          </a>
        </div>
      </div>
    </div>
    <div class="price">
      <span class="label">{{ $t('price') }}:</span>
      <div ref="priceSlider"></div>
      <span class="label">
        <money-format :value="priceFirstValue"
                      :locale='currentLocale'
                      :currency-code='currency'
                      :hide-subunits='true'/> -
        <money-format :value="priceSecondValue"
                      :locale='currentLocale'
                      :currency-code='currency'
                      :hide-subunits='true'/>
      </span>
    </div>
    <a class="btn cancel-button btn-outline-dark md-w100p"
       v-on:click="cancelFilter">
      <span>{{ $t('cancel_filter') }}</span>
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
</template>

<script>
import Vue from 'vue'
import noUiSlider from 'nouislider'
import 'nouislider/distribute/nouislider.css'
import MoneyFormat from 'vue-money-format'
import Log from '@/utils/logger'

export default {
  name: 'ProductsFilter',
  components: {
    'money-format': MoneyFormat,
  },
  props: {
    priceLow: Number,
    priceHigh: Number,
    priceFirstValue: Number,
    priceSecondValue: Number,
    manufacturerId: Number,
  },
  methods: {
    manufacturerSelected: function(manufacturer) {
      this.$emit('change', {
        manufacturerId: manufacturer.id,
        price: {
          firstValue: this.priceFirstValue,
          secondValue: this.priceSecondValue,
        },
      })
    },
    cancelFilter: function() {
      this.$emit('cancel')
    },
  },
  mounted() {
    Vue.nextTick(() => {
      let slider = this.$refs.priceSlider
      noUiSlider.create(slider, {
        start: [this.priceFirstValue, this.priceSecondValue],
        connect: true,
        step: 1,
        range: {
          'min': this.priceLow,
          'max': this.priceHigh,
        },
      })
      slider.noUiSlider.on('set', (event) => {
        this.$emit('change', {
          manufacturerId: this.manufacturerId,
          price:
              {
                firstValue: parseInt(event[0]),
                secondValue: parseInt(event[1]),
              },
        })
      })
    })
  },
  watch: {
    priceFirstValue: function(newVal, oldVal) {
      Vue.nextTick(() => {
        let slider = this.$refs.priceSlider
        slider.noUiSlider.set(newVal)
      })
    },
    priceSecondValue: function(newVal, oldVal) {
      Vue.nextTick(() => {
        let slider = this.$refs.priceSlider
        slider.noUiSlider.set([null, newVal])
      })
    },
  },
}
</script>

<style lang="scss">
@import "../theme/theme";

.products-filter {
  display: inline-block;
  padding-top: 16px;
  padding-bottom: 12px;

  a {
    text-transform: uppercase;
  }

  .big-label {
    display: inline-block;
    color: black;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 35px;
    font-family: $font-family-base;
  }

  .label {
    display: inline-block;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 35px;
    font-family: $font-family-base;
  }

  .price {
    margin-left: 40px;
    display: inline-block;

    @include media-breakpoint-down(md) {
      margin-left: 0;
      width: 100%;
    }

    .label {
      display: inline-block;
    }

    .money_format {
      display: inline-block;
    }

    .noUi-target {
      display: inline-block;
      margin-right: 20px;
      margin-left: 8px;
      transform: translate(0, -5px);
      width: 150px;
      background: #121B15;
      border-radius: 0;
      box-shadow: none;
      height: 2px;
      border: 0;

      @include media-breakpoint-down(md) {
        width: calc(100% - 200px)
      }

      .noUi-base {

        .noUi-handle {
          display: block;
          transform: translate(-5px);
          margin-top: 0;
          width: 15px;
          height: 15px;
          border-radius: 100%;
          background-color: $primary;
          box-shadow: none;

          &:before, &:after {
            display: none;
          }

          .noUi-touch-area {
            width: auto;
            border-radius: 100%;
          }
        }

        .noUi-connect {
          background: $primary;
        }
      }
    }
  }

  .manufacturer {
    display: inline-block;

    ul {
      padding-left: 0;

      li {
        padding-left: 24px;
        list-style: none;

        a {
          color: #878786;
          font-size: 14px;
          max-lines: 1;

        }

        &.active {
          .chevron {
            a {
              color: $primary;
            }

            a:before {
              color: $primary;
            }
          }
        }
      }
    }
  }

  .cancel-button {
    margin-left: 32px;
    float: right;
    display: inline-block;
    font-size: 12px;
    font-family: $font-family-base;
    padding: 6px;
    line-height: 16px;
    background-color: white;
    border: 1px solid black;

    @include media-breakpoint-down(md) {
      position: absolute;
      top: 14px;
      right: 12px;
      line-height: 18px;
      height: 32px;
      width: 32px;

      span {
        display: none;
      }
      i {
        display: inline-block;
      }
    }
    @include media-breakpoint-up(lg) {
      i {
        display: none;
      }
    }
  }
}
</style>