<template>
  <div class="create-account container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-5 col-xl-4">
        <div class="card mb-4">
          <div class="card-body">
            <h1>{{ $t('create_account') }}</h1>
            <div class="form-group">
              <label for="email">{{ $t('email') }}</label>
              <input type="text"
                     id="email"
                     name="email"
                     required
                     class="form-control"
                     :class="{'is-invalid': validated && (input.email.trim().length === 0 || invalidLogin)}"
                     v-model="input.email"/>
              <div>
                <div class="invalid-feedback"
                     :class="{'d-block': validated && input.email.trim().length === 0}">
                  {{ $t('is_required') }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="first_name">{{ $t('first_name') }}</label>
              <input type="text"
                     id="first_name"
                     name="first_name"
                     required
                     class="form-control"
                     :class="{'is-invalid': validated && (input.first_name.trim().length === 0 || invalidLogin)}"
                     v-model="input.first_name"/>
              <div>
                <div class="invalid-feedback"
                     :class="{'d-block': validated && input.first_name.trim().length === 0}">
                  {{ $t('is_required') }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="last_name">{{ $t('last_name') }}</label>
              <input type="text"
                     id="last_name"
                     name="last_name"
                     required
                     class="form-control"
                     :class="{'is-invalid': validated && (input.last_name.trim().length === 0 || invalidLogin)}"
                     v-model="input.last_name"/>
              <div>
                <div class="invalid-feedback"
                     :class="{'d-block': validated && input.last_name.trim().length === 0}">
                  {{ $t('is_required') }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="password">{{ $t('password') }}</label>
              <input type="password"
                     name="password"
                     id="password"
                     required
                     class="form-control"
                     :class="{'is-invalid': validated && (input.password.trim().length === 0 || invalidLogin)}"
                     v-model="input.password"/>
              <div>
                <div class="invalid-feedback"
                     :class="{'d-block': validated && input.password.trim().length === 0}">
                  {{ $t('is_required') }}
                </div>
                <div class="invalid-feedback"
                     :class="{'d-block': validated && weakPassword}">
                  {{ $t('password_is_weak') }}
                </div>
              </div>
            </div>
            <div class="form-check mb-4">
              <input type="checkbox"
                     name="newsletters"
                     id="newsletters"
                     required
                     class="form-check-input"
                     v-model="input.newsletters"/>
              <label for="newsletters" class="form-check-label"
                     v-html="$t('newsletters_subscription')">
              </label>
            </div>
            <button type="button"
                    class="btn btn-primary float-right"
                    v-on:click="createAccount()">{{ $t('create_account') }}
            </button>
          </div>
        </div>
        <router-link :to="loginLink">{{ $t('login') }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import UserRepository from '@/repository/UserRepository.js'
import Log from '@/utils/logger'
import zxcvbn from 'zxcvbn-i18n'

export default {
  name: 'CreateAccount',
  data() {
    return {
      validated: false,
      invalidLogin: false,
      weakPassword: false,
      input: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        newsletters: false,
      },
    }
  },
  methods: {
    createAccount() {
      this.validated = true
      if (this.input.email !== ''
          && this.input.first_name !== ''
          && this.input.last_name !== ''
          && this.input.password !== ''
          && this.validatePasswordStrength()) {
        UserRepository.getInstance().register(
            this.input.email,
            this.input.first_name,
            this.input.last_name,
            this.input.password,
            this.input.newsletters,
        ).then((response) => {
          this.$store.commit('login', response.data)
          let redirect = this.$route.query.redirect
          if (redirect) {
            window.location.href = '/account-created?redirect=' + redirect
          } else {
            window.location.href = '/account-created'
          }
        }).catch((error) => {
          Log.d('error', error)
        })
      } else {
        this.invalidLogin = false
      }
    },
    validatePasswordStrength() {
      let isPasswordStrong = zxcvbn(this.input.password).score >= 3
      this.weakPassword = !isPasswordStrong
      return isPasswordStrong
    },
  },
  computed: {
    loginLink: function() {
      if (this.$route.query.redirect) {
        return '/login?redirect=' + this.$route.query.redirect
      } else {
        return '/login'
      }
    },
  },
  watch: {
    'input.email': function(oldValue, newValue) {
      if (this.validated && this.invalidLogin) {
        this.invalidLogin = false
      }
    },
    'input.first_name': function(oldValue, newValue) {
      if (this.validated && this.invalidLogin) {
        this.invalidLogin = false
      }
    },
    'input.last_name': function(oldValue, newValue) {
      if (this.validated && this.invalidLogin) {
        this.invalidLogin = false
      }
    },
    'input.password': function(oldValue, newValue) {
      this.weakPassword = false
      if (this.validated && this.invalidLogin) {
        this.invalidLogin = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.create-account {
  margin-top: 60px;
  margin-bottom: 60px;

  .form-check-label {
    font-size: 14px;
  }
}
</style>
