<template>
  <div class="account container">
    <div class="row">
      <div class="col">
        <AccountNavigation :active="'account'"/>
        <div class="card mb-4">
          <div class="card-header">
            <h3>{{ $t('settings') }}: {{ user.username }}</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col">
                <UserForm :ref="'user-form'" :prefill="true"/>
                <span class="btn btn-primary float-right" v-on:click="saveUser()">
                  {{ $t('save') }}
                </span>
                <br>
                <h4 class="mt-4">{{ $t('shipping_address') }}</h4>
                <AddressForm :ref="'shipping-address'" :prefill="true" type="shipping"/>
                <span class="btn btn-secondary mb-4" v-on:click="deleteShippingAddress()">
                  {{ $t('delete') }}
                </span>
                <span class="btn btn-primary float-right"
                      v-on:click="saveShippingAddress()">
                  {{ $t('save') }}
                </span>
                <h4 class="mt-4">{{ $t('billing_address') }}</h4>
                <AddressForm :ref="'billing-address'" :prefill="true" type="billing"/>
                <span class="btn btn-secondary" v-on:click="deleteBillingAddress()">
                  {{ $t('delete') }}
                </span>
                <span class="btn btn-primary float-right"
                      v-on:click="saveBillingAddress()">
                  {{ $t('save') }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountNavigation from '@/components/account/AccountNavigation'
import UserRepository from '@/repository/UserRepository'
import AddressForm from '@/components/AddressForm'
import UserForm from '@/components/account/UserForm'

const snakeCaseKeys = require('snakecase-keys')

export default {
  name: 'AccountSettings',
  components: {
    AddressForm,
    AccountNavigation,
    UserForm,
  },
  methods: {
    updateUser: function() {
      UserRepository.getInstance().getUser().then((it) => {
        this.notifySaved()
        this.$router.push('/account')
        this.scrollToTop()
      })
    },
    saveUser: function() {
      let userDataForm = this.$refs['user-form']
      if (userDataForm.validate()) {
        let userData = userDataForm.getData()
        UserRepository.getInstance().updateUserData(userData.email, userData.firstName,
            userData.lastName, userData.ic, userData.dic, userData.newsletters)
            .then((response) => {
              this.updateUser()
            })
            .catch((error) => {
              this.notifyError(error)
            })
      } else this.notifyFormNotValid()
    },
    saveShippingAddress: function() {
      let shippingAddressForm = this.$refs['shipping-address']
      if (shippingAddressForm.validate()) {
        let shippingAddress = snakeCaseKeys(shippingAddressForm.getData())
        UserRepository.getInstance().updateAddress(shippingAddress).then((response) => {
          this.updateUser()
        }).catch((error) => {
          this.notifyError(error)
        })
      } else this.notifyFormNotValid()
    },
    saveBillingAddress: function() {
      let billingAddressForm = this.$refs['billing-address']
      if (billingAddressForm.validate()) {
        let billingAddress = snakeCaseKeys(billingAddressForm.getData())
        UserRepository.getInstance().updateAddress(billingAddress).then((response) => {
          this.updateUser()
        }).catch((error) => {
          this.notifyError(error)
        })
      } else this.notifyFormNotValid()
    },
    deleteShippingAddress: function() {
      this.$confirm({
        message: this.$t('delete_address'),
        button: {
          yes: this.$t('yes'),
          no: this.$t('no'),
        },
        callback: confirm => {
          if (confirm) {
            let shippingAddressForm = this.$refs['shipping-address']
            let shippingAddress = snakeCaseKeys(shippingAddressForm.getData())
            UserRepository.getInstance().deleteAddress(shippingAddress.id)
                .then((response) => {
                  this.updateUser()
                })
                .catch((error) => {
                  this.notifyError(error)
                })
          }
        },
      })
    },
    deleteBillingAddress: function() {
      this.$confirm({
        message: this.$t('delete_address'),
        button: {
          yes: this.$t('yes'),
          no: this.$t('no'),
        },
        callback: confirm => {
          if (confirm) {
            let billingAddressForm = this.$refs['billing-address']
            let billingAddress = snakeCaseKeys(billingAddressForm.getData())
            UserRepository.getInstance().deleteAddress(billingAddress.id)
                .then((response) => {
                  this.updateUser()
                })
                .catch((error) => {
                  this.notifyError(error)
                })
          }
        },
      })
    },
  },
  mounted() {
    UserRepository.getInstance().getUser()
  },
}
</script>

<style lang="scss" scoped>
.account {
  padding-top: 60px;
  padding-bottom: 60px;
}
</style>
