import Vue from 'vue'
import store from '@/store'

Vue.mixin({
  computed: {
    currentLocale: () => store.getters.locale
  },
  methods: {
    switchLocale: function(locale) {
      store.commit("changeLocale", locale)
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
      }
    }
  }
})