<template>
  <div class="user-form">
    <form>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="first_name"><span class="asterisk">*</span>
            {{ $t('first_name') }}
          </label>
          <input type="text" class="form-control" id="first_name"
                 v-bind:class="{'is-invalid': (submitted && errors.firstName)}"
                 v-model="firstName">
        </div>
        <div class="form-group col-md-6">
          <label for="last_name"><span class="asterisk">*</span>
            {{ $t('last_name') }}
          </label>
          <input type="text" class="form-control" id="last_name"
                 v-bind:class="{'is-invalid': (submitted && errors.lastName)}"
                 v-model="lastName">
        </div>
        <div class="form-group col-md-6">
          <label for="ic">
            {{ $t('ic') }}
          </label>
          <input type="text" class="form-control" id="ic"
                 v-bind:class="{'is-invalid': (submitted && errors.ic)}"
                 v-model="ic">
        </div>
        <div class="form-group col-md-6">
          <label for="dic">
            {{ $t('dic') }}
          </label>
          <input type="text" class="form-control" id="dic"
                 v-bind:class="{'is-invalid': (submitted && errors.dic)}"
                 v-model="dic">
        </div>
        <div class="form-group col-md-6">
          <label for="email">
            {{ $t('email') }}
          </label>
          <input type="email" class="form-control" id="email"
                 disabled
                 v-model="email">
        </div>
        <div class="form-check col-md-6 pl-4 mt-md-4 mb-2">
          <input type="checkbox"
                 name="newsletters"
                 id="newsletters"
                 required
                 class="form-check-input"
                 v-model="newsletters"/>
          <label for="newsletters" class="form-check-label"
                 v-html="$t('newsletters_subscription')">
          </label>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import RxUtils from '@/utils/rx_utils'
import _ from 'lodash'

export default {
  name: 'AddressForm',
  props: { 'prefill': Boolean },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      ic: '',
      dic: '',
      newsletters: false,
      submitted: false,
      valid: true,
      errors: {
        firstName: false,
        lastName: false,
        ic: false,
        dic: false
      },
    }
  },
  methods: {
    getData() {
      let data = RxUtils.rxToObject(this.$data)
      delete data._asyncComputed
      delete data.submitted
      return data
    },
    validateEmpty(item) {
      if (_.isEmpty(this[item])) {
        this.valid = false
        this.errors[item] = true
      }
    },
    validate() {
      this.valid = true
      this.submitted = false
      for (let key in this.errors) {
        this.errors[key] = false
      }
      this.validateEmpty('firstName')
      this.validateEmpty('lastName')
      this.submitted = true
      return this.valid
    },
  },
  mounted() {
    if (this.prefill) {
      let user = this.user
      if (user != null) {
        this.firstName = user.first_name
        this.lastName = user.last_name
        this.ic = user.ic
        this.dic = user.dic
        this.email = user.email
        this.newsletters = user.newsletters
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.asterisk {
  font-size: 12px;
  color: red;
  margin-right: 8px;
}
</style>