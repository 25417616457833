import store from '@/store'
import BaseRepository from './BaseRepository.js'
import Log from '@/utils/logger'

export default class CartRepository extends BaseRepository {
  async getManufacturers() {
    return this.get('/manufacturers')
      .then((response) => {
        store.commit('updateManufacturers', response.data.items)
      })
      .catch((err) => {
        Log.e('getManufacturers', err)
      })
  }
}