import Vue from 'vue'
import router from '@/router'
import Cookies from 'js-cookie'
import _ from 'lodash'
import VueGtag from 'vue-gtag'
import AuthenticationUtils from '@/utils/authentication'
import Log from '@/utils/logger'
import CategoriesRepository from '@/repository/CategoriesRepository'
import ProductsRepository from '@/repository/ProductsRepository'
import ManufacturersRepository from '@/repository/ManufacturersRepository'
import UserRepository from '@/repository/UserRepository'
import BannersRepository from '@/repository/BannersRepository'
import ShippingRepository from '@/repository/ShippingRepository'
import PaymentRepository from '@/repository/PaymentRepository'
import PagesRepository from '@/repository/PagesRepository'
import VATRepository from '@/repository/VATRepository'
import MetaRepository from '@/repository/MetaRepository'
import WishlistRepository from '@/repository/WishlistRepository'

function _loadUserAndToken() {
  let token = Cookies.get('token')
  let user = Cookies.get('user')
  if (token) {
    token = JSON.parse(token)
  }
  if (user) {
    user = JSON.parse(user)
  }
  if (!_.isEmpty(token) && !_.isEmpty(user)) {
    Log.d('user', user)
    Log.d('token', token)
    if (AuthenticationUtils.isValidToken(token)) {
      return { customer: user, token: token }
    }
  }
}

function setupAnalytics(metaResponse) {
  Vue.use(VueGtag, {
    config: { id: metaResponse.data.google_analytics_id },
  }, router)
}

let init = async function init(store) {
  let metaResponse = await MetaRepository.getInstance().getMeta()
  store.commit('meta', metaResponse.data)

  if (window.isDebug()) {
    document.body.innerHTML = document.body.innerHTML
      .replace('__FAVICONS__', '')

    document.body.innerHTML = document.body.innerHTML
      .replace('__ESHOP_NAME__', 'NetteEshopVue [debug]')
  }

  if (!window.isDebug() && window.consentGiven()) {
    setupAnalytics(metaResponse)
  }

  const payload = _loadUserAndToken()
  let promises = []
  if (payload) {
    store.commit('login', payload)
    promises.push([
      UserRepository.getInstance().getUser(),
      WishlistRepository.getInstance().getUsersWishlist(),
    ])
  }
  promises.push(
    CategoriesRepository.getInstance().getCategories(),
    ProductsRepository.getInstance().getMeta(),
    ManufacturersRepository.getInstance().getManufacturers(),
    BannersRepository.getInstance().getBanners(),
    ShippingRepository.getInstance().getAllShippingTypes(),
    PaymentRepository.getInstance().getAllPaymentTypes(),
    PagesRepository.getInstance().getPages(),
    VATRepository.getInstance().getAll(),
  )
  await Promise.all(promises)
}

export default init
