<template>
  <div class="product-detail pt-4" v-if="product">
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <div class="row mr-lg-2"
               v-if="product.images != null  && product.images.length > 0">
            <div class="image img-thumbnail text-center"
                 @click="showImage(index + 1)"
                 v-for="(p, index) in product.images"
                 v-bind:key="p.identifier">
              <div class="inside">
                <BackendImage
                    :identifier="p.identifier"
                    :alt="p.title"
                    :size="512"/>
              </div>
              <ProductLabels :product="product" v-if="index === 0"/>
            </div>
          </div>
          <div class="row mr-lg-2" v-else>
            <div class="col-md-6">
              <div class="image img-thumbnail">
                <div class="inside mt-4 mb-4">
                  <BackendImage
                      :identifier="null"
                      :alt="product.title"
                      :size="512"/>
                </div>
                <ProductLabels :product="product"/>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="row">
            <div class="col">
              <h1 class="text-uppercase">
                <backend-translated-text :default-value="product.title"
                                         :translations="product.translations"
                                         :translation-key="'title'"/>
              </h1>
              <div v-if="selectedVariant == null">
                <div class="price before-discount"
                     v-if="getPriceVariants().length > 1 && product.discount > 0">
                  <money-format :value="processPrice(product, getLowestPrice(), false)"
                                :locale='currentLocale'
                                :currency-code='currency'
                                :hide-subunits='true'/>
                  -
                  <money-format :value="processPrice(product, getHighestPrice(), false)"
                                :locale='currentLocale'
                                :currency-code='currency'
                                :hide-subunits='true'/>
                </div>
                <div class="price" v-if="getPriceVariants().length > 1">
                  <money-format :value="processPrice(product, getLowestPrice())"
                                :locale='currentLocale'
                                :currency-code='currency'
                                :hide-subunits='true'/>
                  -
                  <money-format :value="processPrice(product, getHighestPrice())"
                                :locale='currentLocale'
                                :currency-code='currency'
                                :hide-subunits='true'/>
                </div>
                <div class="price" v-else>
                  <money-format :value="processPrice(product)"
                                :locale='currentLocale'
                                :currency-code='currency'
                                :hide-subunits='true'/>
                </div>
              </div>
              <div v-else>
                <div v-if="product.discount > 0" class="price before-discount">
                  <money-format
                      :value="processPrice(selectedVariant, null, false)"
                      :locale='currentLocale'
                      :currency-code='currency'
                      :hide-subunits='true'/>
                </div>
                <div class="price">
                  <money-format :value="processPrice(selectedVariant)"
                                :locale='currentLocale'
                                :currency-code='currency'
                                :hide-subunits='true'/>
                </div>
              </div>
              <p class="small product-id">{{ product.id }}</p>
              <hr>
              <div class="variants" v-if="product.type == 'master_card'">
                <p>
                  <backend-translated-text :default-value="product.variant_name"
                                           :translations="product.translations"
                                           :translation-key="'variant_name'"/>
                </p>
                <div class="btn"
                     v-on:click="product.stock != 0 ? selectVariant(product) : null"
                     v-for="product in product.products"
                     :class="{'active': selectedVariant == product, 'out-of-stock': product.stock == 0}"
                     :key="product.id">
                  {{ getVariantName(product.id) }}
                </div>
                <hr>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p v-if="this.selectedVariant == null && isInStock(this.product) || isVariantInStock()">
                <b>{{ $t('in_stock') }}</b>
              </p>
              <p v-else-if="this.selectedVariant == null && !isInStock(this.product)">
                <b>{{ $t('not_in_stock') }}</b>
              </p>
              <p v-if="this.selectedVariant != null && !isVariantInStock()">
                <b>{{ $t('variant_not_in_stock') }}</b>
              </p>
              <hr v-if="getUsablePaymentTypes().length > 0 || getUsableShippingTypes().length > 0">
              <p class="usable-payment-types" v-if="getUsablePaymentTypes().length > 0">
                <span>{{ $t('usable_payment_types') }}: </span>
                <span class="badge badge-pill badge-dark mb-1"
                      v-for="paymentType in getUsablePaymentTypes()"
                      :key="paymentType.id">
                  {{ paymentType.title }}
                </span>
              </p>
              <p class="usable-shipping-types" v-if="getUsableShippingTypes().length > 0">
                <span>{{ $t('usable_shipping_types') }}: </span>
                <span class="badge badge-pill badge-dark mb-1"
                      v-for="shippingType in getUsableShippingTypes()"
                      :key="shippingType.id">
                  {{ shippingType.title }}
                </span>
              </p>
              <button v-on:click="addToCart()"
                      class="btn btn-primary add-to-cart expanded"
                      :class="{'disabled':
                  product.type == 'master_card' && selectedVariant == null
                  || product.type == 'master_card' && selectedVariant != null && !isVariantInStock()
                  || !isInStock(this.product)}">
                {{ $t('add_to_cart') }}
              </button>
              <br>
              <p v-if="!isInWishlist(product)"
                 class="add-to-wishlist mt-2"
                 v-on:click="addToWishlist(product)">
                {{ $t('add_to_wishlist') }}
              </p>
              <p v-else
                 class="add-to-wishlist mt-2"
                 v-on:click="removeFromWishlist(product)">
                {{ $t('remove_from_wishlist') }}
              </p>

              <a :href="'mailto:' + infoEmail + '?subject=' + $t('ask_about_product') + ': ' + this.product.id">
                {{ $t('ask_about_product') }}
              </a>
              <backend-translated-text class="description mt-4 d-block"
                                       :default-value="product.long_description"
                                       :translations="product.translations"
                                       :translation-key="'long_description'"/>
            </div>
          </div>
        </div>
      </div>
      <FsLightbox
          :toggler="showLightbox"
          :sources="lightboxImages"
          :slide="lightboxIndex"
      />
    </div>
    <div class="container-fluid recently-viewed-wrapper">
      <div class="container">
        <RecentlyViewed class="mt-4"/>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash'
import ProductsRepository from '../repository/ProductsRepository'
import BackendImage from '../components/BackendImage'
import Log from '../utils/logger'
import MoneyFormat from 'vue-money-format'
import FsLightbox from 'fslightbox-vue'
import RxUtils from '@/utils/rx_utils'
import ProductLabels from '@/components/ProductLabels'
import BackendTranslatedText from '@/components/BackendTranslatedText'
import RecentlyViewed from '@/components/RecentlyViewed'

export default {
  name: 'Product',
  components: {
    BackendTranslatedText,
    ProductLabels,
    BackendImage,
    'money-format': MoneyFormat,
    FsLightbox,
    RecentlyViewed,
  },
  data: function() {
    return {
      product: null,
      showLightbox: false,
      lightboxIndex: 0,
      selectedVariant: null,
    }
  },
  computed: {
    category: function() {
      if (this.product != null) return this.product.categories[0].id
      else return null
    },
    lightboxImages: function() {
      if (this.product.images != null)
        return RxUtils.rxToObject(this.product.images).map((i) =>
            window.location.protocol + '//' + this.host + '/data/' + i.identifier)
      else return []
    },
    infoEmail: function() {
      return this.$store.getters.infoEmail
    },
  },
  methods: {
    showImage: function(index) {
      this.lightboxIndex = index
      this.showLightbox = !this.showLightbox
    },
    addToCart: function() {
      if (this.product.type === 'master_card') {
        this.addProductToCart(this.selectedVariant.id,
            this.product.variants.find((it) => it.id === this.selectedVariant.id).name)
      } else {
        this.addProductToCart(this.product.id)
      }
    },
    selectVariant: function(product) {
      if (this.selectedVariant != null && product.id === this.selectedVariant.id) {
        this.selectedVariant = null
      } else {
        this.selectedVariant = product
      }
    },
    getVariantName: function(productId) {
      return this.product.variants.filter((v) => v.id === productId)[0]?.name
    },
    getPriceVariants: function() {
      if (this.product.products != null)
        return this.product.products.map((p) => p.price_with_vat).sort()
      else
        return [this.product.price_with_vat]
    },
    getLowestPrice: function() {
      return _.min(this.getPriceVariants())
    },
    getHighestPrice: function() {
      return _.max(this.getPriceVariants())
    },
    isVariantInStock: function() {
      return this.selectedVariant?.stock > 0
    },
    getUsablePaymentTypes: function() {
      return _.filter(this.getPaymentTypes(), (it) => {
        return _.includes(this.product.payment_type_ids, it.id)
      })
    },
    getUsableShippingTypes: function() {
      return _.filter(this.getShippingTypes(), (it) => {
        return _.includes(this.product.shipping_type_ids, it.id)
      })
    },
    trackAnalytics: function() {
      window.analyticsTrackEvent('ViewContent', {
        'content_ids': this.product.id,
        'content_category': this.product.categories.map((c) => c.title).join(', '),
        'content_name': this.product.title,
        'content_type': 'Product',
        'value': this.product.price,
        'currency': this.currency,
      })
    },
    init: function() {
      ProductsRepository.getInstance()
          .getProduct(this.$route.params.id)
          .then((response) => {
            this.product = response.data
            this.saveRecentlyViewed(this.product)
            this.trackAnalytics();
          })
          .catch((e) => {
            Log.e('getProduct', e)
          })
    },
  },
  watch: {
    '$route.params.id': function() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style lang="scss" scoped>
@import "../theme/theme";

.product-detail {
  padding-bottom: 60px;

  .add-to-wishlist {
    text-decoration: underline;

    &:hover {
      color: $primary;
      cursor: pointer;
    }
  }

  .labels {
    transform: scale(1.4);
    transform-origin: top right;
    right: 8px;
    top: 8px;
  }

  .price {
    color: black;
    font-size: 24px;
    line-height: 40px;
    font-family: $font-family-base;
    display: block;

    &.before-discount {
      font-size: 15px;
      line-height: 25px;
      opacity: 0.7;

      .money_format {
        text-decoration: line-through $red;
      }
    }

    .money_format {
      display: inline-block;
    }
  }

  .image {
    position: relative;
    cursor: pointer;
    margin-bottom: 16px;
    width: 100%;

    .inside {
      img {
        max-width: 100%;
      }
    }

    @include media-breakpoint-down(md) {
      &:first-child {
        display: block;
      }
      display: none;
    }
  }

  .description {
    font-size: 14px;
  }

  .product-id {
    color: $gray-500;
  }

  .variants {
    p {
      font-weight: bold;
    }

    .btn {
      background-color: white;
      border: 1px solid black;
      padding: 4px 12px;
      margin-right: 8px;
      margin-bottom: 8px;
      font-weight: normal;
      font-size: 12px;

      &.active, &:hover {
        background-color: black;
        color: white !important;
      }

      &.out-of-stock {
        filter: contrast(0.05) brightness(1.5);
        pointer-events: none;
        background-color: transparent;
        background-image: gradient(linear, 19.1% -7.9%, 81% 107.9%, color-stop(0, transparent), color-stop(.48, transparent), color-stop(.5, #000), color-stop(.52, transparent), color-stop(1, transparent));
        background-image: repeating-linear-gradient(145deg, transparent 0%, transparent 46%, #000 50%, transparent 54%, transparent 100%);
      }
    }
  }

  .usable-payment-types, .usable-shipping-types {
    .badge {
      font-size: 16px;
      margin-right: 4px;
    }
  }

  .recently-viewed-wrapper {
    padding-top: 30px;
    background-color: $hd-gray;
  }
}
</style>