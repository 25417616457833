<template>
  <div class="order payment container">
    <div class="row">
      <div class="col">
        <h1>{{ $t('order') }} > {{ $t('payment') }}</h1>
        <div class="form-group row mt-lg-4 mb-0">
          <label class="col-3 col-form-label">{{ $t('payment') }}</label>
          <div class="col-5">
            <div class="form-check" v-for="item in filteredPaymentTypes" :key="item.id">
              <input v-model="payment" class="form-check-input" type="radio"
                     name="payment"
                     :id="`payment-${item.id}`" :value="item.id" checked>
              <label class="form-check-label" :for="`payment-${item.id}`">
                <BackendTranslatedText :default-value="item.title"
                                       :translation-key="'title'"
                                       :translations="item.translations"/>
              </label>
            </div>
          </div>
          <div class="col-2 offset-sm-1">
            <div class="price" v-if="payment != 0">
              <money-format
                  :value="selectedPayment.price"
                  :locale='currentLocale'
                  :currency-code='currency'
                  :hide-subunits='true'/>
            </div>
          </div>
        </div>
        <div class="row mt-lg-4 mb-4"
             v-if="payment != 0 && selectedPayment.description">
          <div class="col-sm-2">

          </div>
          <div class="col-sm-6">
            <div class="small">
              <BackendTranslatedText :key="selectedPayment"
                                     :default-value="selectedPayment.description"
                                     :translation-key="'description'"
                                     :translations="selectedPayment.translations"/>
            </div>
          </div>
        </div>
        <CartTotal :count="orderItemsCount" :price="orderPrice" :is-cart="false"/>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="mt-4">
          <a v-on:click="previousStep()" class="btn btn-secondary">
            {{ $t('back') }}
          </a>
          <a v-on:click="nextStep()" class="btn btn-primary float-right"
             :class="{'disabled': payment == 0}">
            {{ $t('continue') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CartTotal from '@/components/CartTotal'
import MoneyFormat from 'vue-money-format'
import BackendTranslatedText from '@/components/BackendTranslatedText'
import * as _ from 'lodash'
import Log from '@/utils/logger'

export default {
  name: 'OrderPayment',
  components: {
    'money-format': MoneyFormat,
    CartTotal,
    BackendTranslatedText,
  },
  data: function() {
    return {
      payment: 0,
    }
  },
  methods: {
    previousStep: function() {
      this.$router.back()
    },
    nextStep: function() {
      let order = this.getCurrentOrder()
      order['price'] = this.orderTotal
      order['payment_type_id'] = this.payment
      order['payment_price'] = this.selectedPayment.price
      this.$store.commit('updateCurrentOrder', order)
      this.$router.push('/orders/create/overview')
      this.scrollToTop()
    },
  },
  computed: {
    orderItemsCount: function() {
      let count = 0
      this.getCurrentOrder().order_items.forEach((it) => count += it.count)
      return count
    },
    selectedPayment: function() {
      return this.getPaymentTypes().find((it) => it.id == this.payment)
    },
    orderPrice: function() {
      let price = 0
      let order = this.getCurrentOrder()
      order.order_items.forEach(
          (it) => price += it.count * this.processPrice(it))

      let selectedShippingType = this.getShippingTypes()
          .find((it) => it.id === order.shipping_type_id && !this.freeShipping)
      if (selectedShippingType != null)
        price += selectedShippingType.price

      let selectedPaymentType = this.getPaymentTypes()
          .find((it) => it.id === this.payment)
      if (selectedPaymentType != null)
        price += selectedPaymentType.price
      return price
    },
    filteredPaymentTypes: function() {
      let currentOrderShippingTypeId = this.getCurrentOrder().shipping_type_id
      let filteredByShippingType = _.filter(this.getPaymentTypes(), function(it) {
        if (it.shipping_type_ids == null || it.shipping_type_ids === '')
          return it
        else {
          let shippingTypeIds = it.shipping_type_ids.toLowerCase().split(',')
          return _.includes(shippingTypeIds, currentOrderShippingTypeId.toString())
        }
      })
      let allowedPaymentTypeIdsByProducts = _.filter(_.flatMap(_.map(this.getCurrentOrder().order_items,
          (it) => it.payment_type_ids?.split('|'),
      )), (it) => it != null && it !== '' && true);
      if (allowedPaymentTypeIdsByProducts?.length === 0) {
        return filteredByShippingType
      } else {
        return _.filter(filteredByShippingType, (it) => {
          return _.includes(allowedPaymentTypeIdsByProducts, it.id.toString())
        })
      }
    },
  },
  mounted() {
    if (this.getCurrentOrder() == null) {
      this.$router.push('/cart')
    }
    if (this.getCurrentOrder()?.payment_type_id != null) {
      this.payment = this.getCurrentOrder().payment_type_id
    }
  },
}
</script>