<template>
  <div class="sub-categories" v-if="getChildCategories(category.id).length > 0">
    <div class="dropdown md-w100p">
      <button class="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false">
        {{ $t('subcategories') }}
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a v-for="item in getChildCategories(category.id)"
           :key="item.id"
           v-on:click="selectCategory(item, item.parent_id)"
           class="dropdown-item">
          <BackendTranslatedText
              :key="item.id"
              :default-value="item.title"
              :translation-key="'title'"
              :translations="item.translations"/>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import BackendTranslatedText from '@/components/BackendTranslatedText'

export default {
  components: {
    BackendTranslatedText,
  },
  name: 'SubCategories',
  props: {
    category: Object,
  },
  methods: {
    selectCategory: function (category, parentId) {
      this.parentId = parentId
      this.$emit('changed', category)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../theme/theme";

.sub-categories {
  display: inline-block;
  margin-top: 14px;
  margin-right: 16px;
}
</style>

