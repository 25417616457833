<template>
  <nav class="nav justify-content-center">
    <router-link class="nav-link" to="/account" :class="{'active': active === 'account'}">
      {{ $t('account') }}
    </router-link>
    <router-link class="nav-link" to="/orders" :class="{'active': active === 'orders'}">
      {{ $t('orders') }}
    </router-link>
    <router-link class="nav-link" to="/wishlist" :class="{'active': active === 'wishlist'}">
      {{ $t('wishlist') }}
    </router-link>
  </nav>
</template>

<script>
export default {
  name: 'AccountNavigation',
  props: ['active'],
}
</script>