export default class Log {
  static d(tag, message) {
    if (window.isDebug()) {
      console.log(`${window.loggerTag()} 📃 ${tag}`, message)
    }
  }

  static e(tag, message) {
    if (window.isDebug()) {
      console.error(`${window.loggerTag()} 🧨 ${tag}`, message)
    }
  }
}