<template>
  <div class="footer">
    <div class="container light">
      <div class="row">
        <div class="col-lg-4">
          <div class="card footer-box mb-sm-4 mb-4">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-4 col-md-2 col-3">
                  <img class="img-fluid" src="/assets/truck.svg" alt="">
                </div>
                <div class="col">
                  <p class="card-title">{{ $t('free_shipping') }}</p>
                  <p class="card-text">
                    {{
                      $t('free_shipping_text')
                          .replace('__FREE_SHIPPING_PRICE__', this.productsMeta.free_shipping_price)
                    }}
                    <br>
                    {{
                      $t('free_shipping_countries_text')
                          .replace('__FREE_SHIPPING_COUNTRIES__', this.productsMeta.free_shipping_countries.toString().toUpperCase())
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card footer-box mb-sm-4 mb-4">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-4 col-md-2 col-3">
                  <img class="img-fluid" src="/assets/money-back.svg" alt="">
                </div>
                <div class="col">
                  <p class="card-title">{{ $t('money_back') }}</p>
                  <p class="card-text">{{ $t('money_back_text') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card footer-box">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-4 col-md-2 col-3">
                  <img class="img-fluid" src="/assets/support.svg" alt="">
                </div>
                <div class="col">
                  <p class="card-title">{{ $t('support') }}</p>
                  <p class="card-text">{{ $t('support_text') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dark">
      <div class="container">
        <div class="col-lg-10 offset-lg-1">
          <div class="row">
            <div class="col-lg-4 text-left">
              <img src="/assets/hd-logo.svg" alt="HD Logo"
                   class="img-fluid mt-lg-4 mt-md-4 mb-5">
            </div>
            <div class="col-lg-4 newsletter text-left">
              <p>{{ $t('newsletter_signup') }}</p>
              <p class="big">{{ $t('stay_in_the_know') }}</p>
            </div>
            <div class="col-lg-4 text-left">
              <a v-on:click="openCreateAccount()" class="btn btn-primary mt-lg-2 mt-md-2">
                {{ $t('sign_up_for_email') }}
              </a>
            </div>
          </div>
        </div>
        <hr class="mb-5">
        <div class="col-lg-10 offset-lg-1">
          <div class="row links">
            <div class="col-lg-4">
              <p class="big">{{ $t('customer_support') }}</p>
              <PageLink v-for="page in getPagesFromModule('customer_support')"
                        :key="page.id"
                        :page="page"/>
            </div>
            <div class="col-lg-4">
              <p class="big">{{ $t('about_us') }}</p>
              <PageLink v-for="page in getPagesFromModule('about')"
                        :key="page.id"
                        :page="page"/>
            </div>
            <div class="col-lg-4">
              <p class="big">{{ $t('services') }}</p>
              <PageLink v-for="page in getPagesFromModule('services')"
                        :key="page.id"
                        :page="page"/>
            </div>
          </div>
        </div>
        <hr class="mb-5">
        <div class="row copyright">
          <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
            ©{{ new Date().getFullYear() }} H-D or its affiliates. HARLEY-DAVIDSON,
            HARLEY, H-D, and the Bar and
            Shield Logo are among the trademarks of H-D U.S.A., LLC. Third-party
            trademarks are the property of their respective owners.
          </div>
          <div class="col-lg-2 social-links text-sm-center mt-sm-4 mt-lg-0 mt-md-4">
            <a v-if="youtubeLink != null && youtubeLink != ''" :href="youtubeLink"
               target="_blank">
              <i class="fab fa-youtube"></i>
            </a>
            <a v-if="facebookLink != null && facebookLink != ''" :href="facebookLink"
               target="_blank">
              <i class="fab fa-facebook"></i>
            </a>
            <a v-if="instagramLink != null && instagramLink != ''" :href="instagramLink"
               target="_blank">
              <i class="fab fa-instagram"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MetaRepository from '@/repository/MetaRepository'
import PageLink from '@/components/PageLink'

export default {
  name: 'Footer',
  components: {
    PageLink,
  },
  data: function() {
    return {
      facebookLink: null,
      youtubeLink: null,
      instagramLink: null,
    }
  },
  methods: {
    openCreateAccount: function() {
      this.$router.push({ path: '/create-account' })
      this.scrollToTop()
    },
  },
  mounted() {
    MetaRepository.getInstance().getMeta().then((response) => {
      this.facebookLink = response.data.facebook_link
      this.youtubeLink = response.data.youtube_link
      this.instagramLink = response.data.instagram_link
    })
  },
}
</script>

<style lang="scss" scoped>
@import "../theme/theme";

.footer {
  .light {
    padding-top: 60px;
    padding-bottom: 40px;

    .footer-box {
      img {
        height: 80px;
        width: auto;
      }

      .card-title {
        font-family: $font-family-base;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 16px;
        letter-spacing: 0.1px;
        margin-bottom: 0;
      }

      .card-text {
        color: $gray-600;
        font-size: 12px;
      }
    }
  }

  .dark {
    &:before {
      content: '';
      display: block;
      top: -30px;
      position: relative;
      width: 100%;
      height: 8px;
      background-image: url("/assets/divider-2_desktop_white.svg");
      background-size: auto auto;
      transform: rotate(180deg);
    }

    padding: 30px 0;
    background-color: black;
    background-image: url("/assets/page-background-v2.jpg");
    background-size: 100% auto;

    .newsletter {
      p {
        color: $primary;
        font-size: 14px;
        font-family: $font-family-base;
        text-transform: uppercase;
        margin-bottom: 0;

        &.big {
          text-transform: none;
          color: white;
          margin-top: 0;
          font-size: 30px;
          font-family: $headings-font-family;
        }
      }
    }

    hr {
      display: block;
      border-top: 1px solid $gray-700;
    }

    .links {
      .big {
        font-family: $font-family-base;
        color: white;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 16px;
        margin-bottom: 8px;
        letter-spacing: 0.1px;
      }

      a {
        display: block;
        color: white;
        margin-bottom: 8px;
        font-size: 14px;
      }
    }

    .copyright {
      font-size: 12px;
      color: $gray-600;
    }
  }

  .social-links {
    a {
      i {
        font-size: 27px;
        margin-right: 16px;
        color: white;
      }

      &:hover {
        i {
          color: $primary;
        }
      }
    }
  }
}
</style>