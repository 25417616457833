<template>
  <div class="categories">
    <h1>{{ $t('categories') }}</h1>
    <ul v-if="categories">
      <li v-for="category in categories" v-bind:key="category.id">
        <router-link
            :to="{ path: '/products', query: { category: category.id } }">
          <h2>
            <BackendTranslatedText
                :key="category.id"
                :default-value="category.title"
                :translation-key="'title'"
                :translations="category.translations"/>
          </h2>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import BackendTranslatedText from '@/components/BackendTranslatedText'

export default {
  name: 'Categories',
  components: {
    BackendTranslatedText,
  }
}
</script>

<style lang="less" scoped>

</style>