import store from '@/store'
import BaseRepository from '@/repository/BaseRepository'

export default class PaymentRepository extends BaseRepository {
  async getAllPaymentTypes() {
    return this.get('/payment-types/')
      .then((response) => {
        store.commit('updatePayments', response.data.items)
        return response.data.items
      })
  }

  async createPayment(orderHash) {
    this.axios.defaults.timeout = this.LONG_TIMEOUT
    let request = this.post('/payment/create/', {
      "order_hash": orderHash
    })
    this.axios.defaults.timeout = this.DEFAULT_TIMEOUT
    return request
  }
}