<template>
  <div class="cart container">
    <div class="row">
      <div class="col">
        <h1>{{ $t('cart') }}</h1>
        <div class="items">
          <template v-for="item in items">
            <template v-if="item && itemCount(item.id) > 0">
              <CartItem :key="item.id"
                        :item="item"
                        :is-cart="true"
                        :item-count="itemCount(item.id)"/>
            </template>
          </template>
        </div>
        <CartTotal :count="cartCount()" :price="cartTotal" :is-cart="true"/>
        <div class="mt-4">
          <a v-on:click="nextStep()"
             class="btn btn-primary float-right"
             :class="{'disabled': cartCount() === 0}">
            {{ $t('continue') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CartItem from '@/components/CartItem'
import CartTotal from '@/components/CartTotal'
import LocalDataRepository from '@/repository/LocalDataRepository'
import CartRepository from '@/repository/CartRepository'
import ProductsRepository from '@/repository/ProductsRepository'
import RxUtils from '@/utils/rx_utils'

export default {
  name: 'Cart',
  components: {
    CartItem, CartTotal,
  },
  data() {
    return {
      items: [],
    }
  },
  methods: {
    update: async function (callback) {
      this.loading(true)
      ProductsRepository.getInstance().clearCache()
      this.items = await this.getCompleteCart()
      if (this.$route.query.mergeCarts) {
        this.mergeCarts()
      }
      callback()
    },
    itemCount: function (productId) {
      let product = this.cart().find(item => item.product_id === productId)
      if (product != null)
        return product.count
      else return 0
    },
    nextStep() {
      if (this.isAuthenticated()) {
        this.trackAnalyticsInitiateCheckout()
        this.proceed()
      } else {
        this.$confirm({
          message: this.$t('continue_without_account'),
          button: {
            yes: this.$t('yes'),
            no: this.$t('no'),
          },
          callback: confirm => {
            if (confirm) {
              this.proceed()
            } else {
              this.goToLogin()
            }
          },
        })
      }
    },
    trackAnalyticsInitiateCheckout() {
      window.analyticsTrackEvent('InitiateCheckout', {
        'content_category': 'InitiateCheckout',
        'content_ids': this.items.map((i) => i.id),
        'contents': RxUtils.rxToObject(this.items),
        'currency': this.currency,
        'num_items': this.items.length,
        'value': this.orderTotal,
      })
    },
    goToLogin() {
      this.$router.push({path: '/login', query: {redirect: '/cart?mergeCarts=true'}})
      this.scrollToTop()
    },
    async proceed() {
      let currentOrder = this.getCurrentOrder()
      await this.update(() => this.loading(false))
      if (currentOrder != null) {
        currentOrder['order_items'] = this.items
      } else {
        currentOrder = {
          'order_items': this.items,
        }
      }
      this.$store.commit('updateCurrentOrder', currentOrder)
      await this.$router.push('/orders/create/address')
      this.scrollToTop()
    },
    mergeCarts: function () {
      let localCart = LocalDataRepository.getInstance().loadLocalCart()
      localCart.forEach((it) => {
        CartRepository.getInstance().addProductToCart(it.product_id, it.variant)
      })
      LocalDataRepository.getInstance().emptyLocalCart()
      window.location.href = '/cart'
    },
  },
  mounted() {
    this.update(() => this.loading(false))
  },
}
</script>

<style lang="scss" scoped>
@import "../theme/theme";

.cart {
  padding-top: 60px;
  padding-bottom: 60px;

  .total-label, .price, .count {
    color: black;
    font-size: 18px;
    line-height: 22px;
    font-family: $headings-font-family;

    @include media-breakpoint-up(md) {
      font-size: 24px;
      line-height: 30px;
    }

    &.total {
      color: $primary;
    }

    .money_format {
      display: inline-block;
    }
  }
}

</style>