<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-3">
          <img class="img-fluid pb-sm-2"
               v-bind:src="packetaSelectedPoint.photo[0].normal">
        </div>
        <div class="col-9">
          <h3>{{ $t('selected_pickup_point') }}</h3>
          <div>
            <a target="_blank" v-bind:href="packetaSelectedPoint.url">
              {{ packetaSelectedPoint.place }}
            </a>
          </div>
          <div>{{ packetaSelectedPoint.name }}</div>
          <div v-html="packetaSelectedPoint.openingHours.compactLong"></div>
          <hr>
          <div v-html="packetaSelectedPoint.directions"></div>
          <div v-html="packetaSelectedPoint.directionsCar"></div>
          <div v-html="packetaSelectedPoint.directionsPublic"></div>
          <hr v-if="selectDifferentPacketaPlace != null">
          <a class="btn btn-secondary"
             v-if="selectDifferentPacketaPlace != null"
             v-on:click="selectDifferentPacketaPlace()">
            {{ $t('select_different_pickup_point') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PacketaSelectedPoint',
  props: {
    packetaSelectedPoint: Object,
    selectDifferentPacketaPlace: Function,
  },
}
</script>