<template>
  <div class="products-grid pt-4">
    <div class="row">
      <div class="col-md-3 pt-2">
        <p class="products-count">{{ count }} {{ $t('products') }}</p>
      </div>
      <div class="col-md-6">
        <nav>
          <vuejs-paginate
              v-if="pages > 1"
              v-model="page"
              :prev-text="$t('previous')"
              :next-text="$t('next')"
              :page-count="pages"
              :page-range="5"
              :margin-pages="2"
              :click-handler="selectPage"
              :hide-prev-next="true"
              :container-class="'pagination'"
              :page-link-class="'page-link'"
              :page-class="'page-item'"/>
        </nav>
      </div>
      <div class="col-md-3">
        <ProductSort @change="sortChange" :value="sort"/>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-3 col-md-4" v-for="item in products" :key="item.id">
        <Product :product="item"/>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 pt-2">
        <p class="products-count">{{ count }} {{ $t('products') }}</p>
      </div>
      <div class="col-md-7">
        <nav>
          <vuejs-paginate
              v-if="pages > 1"
              v-model="page"
              :prev-text="$t('previous')"
              :next-text="$t('next')"
              :page-count="pages"
              :page-range="5"
              :margin-pages="2"
              :click-handler="selectPage"
              :hide-prev-next="true"
              :container-class="'pagination'"
              :page-link-class="'page-link'"
              :page-class="'page-item'"/>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import VuejsPaginate from 'vuejs-paginate'
import Product from './Product'
import ProductSort from '@/components/ProductSort'

export default {
  name: 'ProductsGrid',
  props: ['products', 'page', 'pages', 'count', 'selectPage', 'sort', 'sortChange'],
  components: {
    VuejsPaginate,
    Product,
    ProductSort,
  },
}
</script>

<style lang="scss">
@import "../theme/theme";

.pagination {
  align-items: center;
  justify-content: center;
  background: transparent;
  line-height: 35px;

  li:not(.page-item) {
    padding: 0 10px;
  }

  li:hover {
    background-color: black;
     a {
       color: white !important;
     }
  }

  .page-item {

    &.active {
      a {
        color: black;
        font-weight: bold;
      }
    }

    .page-link {
      color: black;
      background: none;
      border: none;
    }
  }
}
</style>