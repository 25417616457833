<template>
  <span :ref="'label'" v-if="defaultValue"></span>
</template>

<script>
import rx_utils from '@/utils/rx_utils'

export default {
  name: 'BackendTranslatedText',
  props: {
    translationKey: String,
    defaultValue: String,
    translations: Object,
  },
  methods: {
    process: function() {
      let t = null
      if (this.translations != null) {
        t = rx_utils.rxToObject(this.translations)
      }
      let label = this.defaultValue
      if (t != null
          && t[this.currentLocale] != null
          && t[this.currentLocale][this.translationKey] != null
          && t[this.currentLocale][this.translationKey] !== '') {
        label = t[this.currentLocale][this.translationKey]
      }
      if (this.$refs.label != null)
        this.$refs.label.innerHTML = label
    },
  },
  watch: {
    currentLocale: function() {
      this.process()
    },
  },
  mounted() {
    this.process()
  },
}
</script>