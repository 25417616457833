<template>
  <div class="card mb-2">
    <div class="card-body">
      <div class="row">
        <div class="thumb col-md-1 col-2 p-0 p-sm-0 p-md-0 float-sm-left float-left">
          <BackendImage
              v-if="item.images != null"
              class="card-img-top"
              :identifier="item.images[0].identifier"
              :alt="item.title"
              :size="250"/>
          <BackendImage
              v-else-if="item.product_imprint != null && item.product_imprint.images != null"
              class="card-img-top"
              :identifier="item.product_imprint.images[0].identifier"
              :alt="item.title"
              :size="250"/>
          <BackendImage
              v-else
              class="card-img-top"
              :identifier="null"
              :alt="item.title"
              :size="250"/>
        </div>
        <div class="title col-md-6 col-10 mt-lg-3"
             :class="{'col-md-8': !isCart, 'col-5': !isCart}">
          <span class="card-text product-title">
            <span @click="open(item)" :class="{'clickable': !this.disableLinks}">
              {{ item.title }}
              <span v-if="getVariantName(item.id)"> - {{ getVariantName(item.id) }}</span>
            </span>
            <p class="small" v-if="item.product_id">{{ item.product_id }}</p>
            <p class="small" v-if="item.id">{{ item.id }}</p>
          </span>
        </div>
        <div class="count offset-4 offset-md-5 offset-lg-0 col-md-2 col-2 col-lg-1 mt-lg-3 text-right"
             :class="{'offset-6 offset-sm-6 offset-md-6 offset-lg-0 offset-xl-0': !isCart}">
          <span class="count">{{ itemCount }}x</span>
        </div>
        <div class="price col-md-3 col-4 col-lg-2 text-right mt-lg-3">
          <div class="price">
            <money-format v-if="!this.freeShippingProp"
                          :value="processPrice(item)"
                          :locale='currentLocale'
                          :currency-code='currency'
                          :hide-subunits='true'/>
            <money-format v-else
                          :value="item.price_before_free_shipping"
                          :locale='currentLocale'
                          :currency-code='currency'
                          :hide-subunits='true'
                          class="strikethrough"/>
            <p class="small" v-if="this.freeShippingProp">{{ $t('free_shipping') }}</p>
          </div>
        </div>
        <div class="buttons col-12 col-sm-12 col-md-2 col-lg-2 text-md-right mt-lg-3" v-if="isCart">
          <button class="btn btn-outline-dark btn-xs"
                  @click="removeProductFromCart(item.id)">
            -
          </button>
          <button class="btn btn-outline-dark btn-xs ml-2"
                  @click="addProductToCart(item.id, item.variant)">
            +
          </button>
        </div>
      </div>
      <a v-if="promoCodeIds != null && promoCodeIds.length > 0"
         v-on:click="$emit('resentPromoCodesClicked', promoCodeIds)"
         class="btn btn-sm btn-outline-primary resent-button float-right">
        {{ $t('resent_to_email') }}
      </a>
    </div>
  </div>
</template>

<script>
import BackendImage from '@/components/BackendImage'
import MoneyFormat from 'vue-money-format'
import PromoCodesRepository from '@/repository/PromoCodesRepository'

export default {
  name: 'CartItem',
  components: {
    BackendImage,
    'money-format': MoneyFormat,
  },
  props: {
    'item': Object,
    'isCart': Boolean,
    'itemCount': Number,
    'disableLinks': Boolean,
    'freeShippingProp': Boolean,
    'promoCodeIds': Array
  },
  methods: {
    getVariantName: function(productId) {
      if (this.isCart) {
        return this.cart().find((it) => it.product_id === productId).variant
      } else {
        return ''
      }
    },
    open: function(item) {
      if (!this.disableLinks)
        this.openProduct(item.id || item.product_id)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../theme/theme";

.price, .count, .product-title {
  color: black;
  font-size: 16px;
  line-height: 22px;
  font-family: $font-family-base;
  font-weight: bold;
  letter-spacing: 0.1px;

  .clickable {
    color: black;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  @include media-breakpoint-up(md) {
    font-size: 20px;
    line-height: 30px;
  }

  &.total {
    color: $primary;
  }

  .money_format {
    display: inline-block;
  }
}

</style>