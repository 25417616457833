<template>
  <div class="orders container">
    <div class="row">
      <div class="col">
        <AccountNavigation :active="'account'"/>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <h3>{{ $t('orders') }}</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="card mb-3" v-for="order in orders" :key="order.id">
              <div class="card-header">
                <FormattedDate class="float-right" :date="order.order_date"/>
                <div class="badge-secondary float-left p-1 pl-3 pr-3 mr-3">
                  {{ $t('order') }}: {{ order.id }}
                </div>
                <div class="badge-primary float-left p-1 pl-3 pr-3">
                  <money-format class="d-inline-block"
                                :value="order.price"
                                :locale='currentLocale'
                                :currency-code='currency'
                                :hide-subunits='true'/>
                </div>
              </div>
              <div class="card-body">
                <div class="order-item"
                     v-for="(item, index) in order.order_items"
                     :key="`${order.id}_${index}`">
                  <div class="row">
                    <div class="col-3 col-sm-2 col-lg-1 image">
                      <BackendImage
                          v-if="item.product_imprint != null && item.product_imprint.images != null"
                          class="card-img-top"
                          :identifier="item.product_imprint.images[0].identifier"
                          :alt="item.title"
                          :size="80"/>
                    </div>
                    <div class="col-9 col-sm-10 col-lg-11">
                      <div class="row">
                        <b>{{ item.product_imprint.title }}</b>
                      </div>
                      <div class="row">
                        <span class="pr-1">{{ item.count }} x </span>
                        <money-format class="d-inline-block"
                                      :value="item.product_imprint.price"
                                      :locale='currentLocale'
                                      :currency-code='currency'
                                      :hide-subunits='true'/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row text-right">
                  <div class="col">
                    <router-link class="btn btn-outline-primary btn-sm"
                                 :to="`/orders/${order.hash}/state`">
                      {{ $t('order_detail') }}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash'
import AccountNavigation from '../components/account/AccountNavigation'
import FormattedDate from '@/components/FormattedDate'
import MoneyFormat from 'vue-money-format'
import BackendImage from '@/components/BackendImage'

export default {
  name: 'Orders',
  components: {
    AccountNavigation,
    FormattedDate,
    'money-format': MoneyFormat,
    BackendImage,
  },
  data() {
    return {
      orders: [],
    }
  },
  methods: {
    getItemsCount(order) {
      let count = 0
      order.order_items.forEach((it) => {
        count += it.count
      })
      return count
    },
  },
  mounted() {
    this.getOrders().then((response) => {
      this.orders = _.reverse(_.sortBy(response.data.orders, ['id']))
    })
  },
}
</script>

<style lang="scss" scoped>
@import "../theme/theme";

.orders {
  padding-top: 60px;
  padding-bottom: 60px;

  .order-item {
    border-bottom: 1px solid $hd-gray;
    padding-bottom: 12px;
    margin-bottom: 12px;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .image {
      img {
        max-width: 100%;
      }
    }
  }
}
</style>
