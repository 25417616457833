<template>
  <div class="order shipping container">
    <div class="row">
      <div class="col">
        <h1>{{ $t('order') }} > {{ $t('shipping') }}</h1>
        <div class="form-group row mt-lg-4 mb-0">
          <label class="col-3 col-form-label">{{ $t('shipping') }}</label>
          <div class="col-5">
            <div class="form-check" v-for="item in filteredShippingTypes" :key="item.id">
              <input v-model="shipping" class="form-check-input" type="radio"
                     name="shipping"
                     :id="`shipping-${item.id}`" :value="item.id" checked>
              <label class="form-check-label" :for="`shipping-${item.id}`">
                <BackendTranslatedText :default-value="item.title"
                                       :translation-key="'title'"
                                       :translations="item.translations"/>
              </label>
            </div>
            <div v-if="filteredShippingTypes.length === 0">
              <p class="alert alert-warning">{{ $t('sorry_no_shipping') }}</p>
            </div>
          </div>
          <div class="col-2 offset-sm-1">
            <div class="price" v-if="shipping != 0">
              <money-format
                  :class="{'strikethrough': freeShipping}"
                  :value="selectedShipping.price"
                  :locale='currentLocale'
                  :currency-code='currency'
                  :hide-subunits='true'/>
              <p class="small" v-if="freeShipping">{{ $t('free_shipping') }}</p>
            </div>
          </div>
        </div>
        <div class="row mt-lg-4 mb-4" v-if="shipping != 0">
          <div class="col-sm-2" v-if="selectedShipping.images != null">
            <BackendImage class="img-fluid"
                          :identifier="selectedShipping.images[0].identifier"
                          :size="250"/>
          </div>
          <div class="col-sm-6">
            <BackendTranslatedText :key="selectedShipping.id"
                                   :default-value="selectedShipping.description"
                                   :translation-key="'description'"
                                   :translations="selectedShipping.translations"/>
          </div>
        </div>
        <div class="row mt-lg-4 mb-4 mt-4"
             v-if="shipping != 0 && selectedShipping.delivery_type === deliveryTypes.zasilkovna">
          <div ref="packeta_widget" class="packeta-widget col"
               v-bind:class="{'is-selected': packetaSelectedPoint != null}">
            <PacketaSelectedPoint v-if="packetaSelectedPoint != null"
                                  :packetaSelectedPoint="packetaSelectedPoint"
                                  :selectDifferentPacketaPlace="selectDifferentPacketaPlace"/>
          </div>
        </div>
        <CartTotal :count="orderItemsCount" :price="orderPrice" :is-cart="false"/>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="mt-4">
          <a v-on:click="previousStep()" class="btn btn-secondary">
            {{ $t('back') }}
          </a>
          <a v-on:click="nextStep()" class="btn btn-primary float-right"
             :class="{'disabled': !canContinue}">
            {{ $t('continue') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash'
import CartTotal from '@/components/CartTotal'
import MoneyFormat from 'vue-money-format'
import BackendImage from '@/components/BackendImage'
import BackendTranslatedText from '@/components/BackendTranslatedText'
import * as zasilkovna from '@/utils/zasilkovna'
import PacketaSelectedPoint from '@/components/PacketaSelectedPoint.vue'

export default {
  name: 'OrderShipping',
  components: {
    PacketaSelectedPoint,
    'money-format': MoneyFormat,
    CartTotal,
    BackendImage,
    BackendTranslatedText,
  },
  data: function() {
    return {
      shipping: 0,
      packetaSelectedPoint: null,
    }
  },
  methods: {
    previousStep: function() {
      this.$router.back()
    },
    nextStep: function() {
      let order = this.getCurrentOrder()
      order['price'] = this.orderTotal
      order['shipping_type_id'] = this.shipping
      order['shipping_price'] = this.selectedShipping.price
      order['state'] = 'pending'
      order['additional_data'] = {
        'packeta': this.packetaSelectedPoint,
      }
      this.$store.commit('updateCurrentOrder', order)
      this.$router.push(`/orders/create/payment`)
      this.scrollToTop()
    },
    showPacketaDialog: function() {
      window.showPacketaWidget(this.$refs.packeta_widget, (data) => {
        this.$data.packetaSelectedPoint = data
      })
    },
    hidePacketaDialog: function() {
      window.hidePacketaWidget()
    },
    selectDifferentPacketaPlace: function() {
      this.packetaSelectedPoint = null
      this.showPacketaDialog()
    },
  },
  computed: {
    canContinue: function() {
      if (this.shipping == 0) {
        return false
      } else {
        if (this.selectedShipping.delivery_type == this.deliveryTypes.zasilkovna) {
          return this.packetaSelectedPoint != null
        } else {
          return true
        }
      }
    },
    orderItemsCount: function() {
      let count = 0
      this.getCurrentOrder().order_items.forEach((it) => count += it.count)
      return count
    },
    selectedShipping: function() {
      return this.getShippingTypes().find((it) => it.id == this.shipping)
    },
    orderPrice: function() {
      let price = 0
      this.getCurrentOrder().order_items.forEach(
          (it) => price += it.count * this.processPrice(it))
      let selectedShippingType = this.getShippingTypes()
          .find((it) => it.id == this.shipping)
      if (selectedShippingType != null && !this.freeShipping)
        price += selectedShippingType.price
      return price
    },
    filteredShippingTypes: function() {
      let currentOrderShippingCountry = this.getCurrentOrder().shipping_address.country
      let currentOrderProductsAvailableShippingTypes = this.getCurrentOrder()
          .order_items
          .flatMap(function(i) {
            if (i.shipping_type_ids === null || i.shipping_type_ids.trim().length === 0) {
              return []
            } else {
              return i.shipping_type_ids.split('|').map((t) => parseInt(t)) ?? []
            }
          })
      let filteredByCountry = _.filter(this.getShippingTypes(), function(it) {
        if (it.countries == null || it.countries === '')
          return it
        else {
          let countries = it.countries.toLowerCase().split(',')
          return countries.includes(currentOrderShippingCountry)
        }
      })
      if (currentOrderProductsAvailableShippingTypes.length > 0) {
        return _.filter(filteredByCountry, function(it) {
          return currentOrderProductsAvailableShippingTypes.includes(it.id)
        })
      } else {
        return filteredByCountry
      }
    },
  },
  watch: {
    selectedShipping: function(shipping) {
      if (shipping.delivery_type == this.deliveryTypes.zasilkovna) {
        this.packetaSelectedPoint = null
        let self = this
        setTimeout(function() {
          self.showPacketaDialog()
        }, 500)
      } else {
        this.hidePacketaDialog()
      }
    },
  },
  mounted() {
    zasilkovna
    if (this.getCurrentOrder() == null) {
      this.$router.push('/cart')
    }
    if (this.getCurrentOrder()?.shipping_type_id != null) {
      this.shipping = this.getCurrentOrder().shipping_type_id
    }
  },
}
</script>

<style lang="scss" scoped>
.packeta-widget {
  height: 600px;
  width: 100%;

  &.is-selected {
    height: auto;
  }
}
</style>