<template>
  <div class="order overview container">
    <div class="row">
      <div class="col">
        <h1>{{ $t('order') }} - {{ $t('overview') }}</h1>
        <h2>{{ $t('cart') }}</h2>
        <div class="items" :key="this.orderTotal">
          <template v-for="item in items">
            <template v-if="item && itemCount(item.id) > 0">
              <CartItem :key="item.id"
                        :item="item"
                        :disable-links="true"
                        :is-cart="false"
                        :item-count="itemCount(item.id)"/>
            </template>
          </template>
          <div v-if="bonusItem != null" class="bonus-item-wrapper">
            <CartItem :item="bonusItem"
                      :is-cart="false"
                      :disable-links="true"
                      :item-count="1"/>
            <a class="btn btn-outline-danger btn-sm btn-remove"
               v-on:click="removeBonus()">
              {{ $t('remove_bonus') }}
            </a>
          </div>
          <CartItem :item="shippingItem"
                    :is-cart="false"
                    :free-shipping-prop="freeShipping"
                    :disable-links="true"
                    :item-count="1"/>
          <div v-if="promoItem != null" class="promo-item-wrapper">
            <CartItem :item="promoItem"
                      :is-cart="false"
                      :disable-links="true"
                      :item-count="1"/>
            <a class="btn btn-outline-danger btn-sm btn-remove"
               v-on:click="removePromoCode()">
              {{ $t('remove_promo_code') }}
            </a>
          </div>
          <CartItem :item="paymentItem"
                    :is-cart="false"
                    :disable-links="true"
                    :item-count="1"/>
        </div>
        <CartTotal :count="cartCount()" :price="this.orderTotal" :is-cart="false"/>
        <div class="offset-lg-6 col-lg-6 offset-xl-8 col-xl-4">
          <div class="form-check mt-4 terms-check">
            <input type="checkbox"
                   name="terms_check"
                   id="terms_check"
                   required
                   class="form-check-input"
                   v-model="termsCheck"/>
            <label for="terms_check" class="form-check-label" ref="terms_check_label">
            </label>
          </div>
        </div>
        <div class="mt-4">
          <a v-on:click="previousStep()" class="btn btn-secondary mb-4">
            {{ $t('back') }}
          </a>
          <div class="float-right">
            <a v-if="this.$store.getters.currentOrder.promo_code == null"
               v-on:click="usePromoCode()"
               class="btn btn-secondary mr-2 mb-2">
              {{ $t('use_promo_code') }}
            </a>
            <a v-if="getUsableBonus() && (this.getCurrentOrder().bonus_value === 0 || this.getCurrentOrder.bonus_value == null)"
               v-on:click="useBonus()" class="btn btn-secondary mr-2 mb-2">
              {{ $t('use_bonus') }} ({{ $t('up_to') }}:
              <money-format :value="getUsableBonus()"
                            :locale='currentLocale'
                            :currency-code='currency'
                            :hide-subunits='true'/>
              )
            </a>
            <a v-on:click="createOrder()" class="btn btn-primary mb-2"
               :class="{'disabled': !termsCheck}">
              {{ $t('create_order') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CartTotal from '@/components/CartTotal'
import CartItem from '@/components/CartItem'
import Log from '@/utils/logger'
import MetaRepository from '@/repository/MetaRepository'
import MoneyFormat from 'vue-money-format'
import bootbox from 'bootbox'
import ProductsRepository from '@/repository/ProductsRepository'
import RxUtils from '@/utils/rx_utils'

export default {
  name: 'OrderOverview',
  components: {
    CartTotal,
    CartItem,
    'money-format': MoneyFormat,
  },
  data: function() {
    return {
      items: [],
      termsCheck: false,
    }
  },
  methods: {
    previousStep: function() {
      this.$router.back()
    },
    itemCount: function(productId) {
      let product = this.cart()
          .find(item => item.product_id === productId)
      if (product != null)
        return product.count
      else return 0
    },
    loadCart: async function() {
      this.items = await this.getCompleteCart()
      this.loading(false)
    },
    createOrder: function() {
      this.loading(true)
      ProductsRepository.getInstance().clearCache()
      this.submitCurrentOrder((orderHash) => {
        this.$router.push(`/orders/${orderHash}/state`)
        this.scrollToTop()
        this.trackAnalyticsPurchase()
        this.loading(false)
      }, (err) => {
        Log.e('submitCurrentOrder', err)
        this.notifyError(err)
        this.loading(false)
      })
    },
    getUsableBonus: function() {
      let currentOrder = this.$store.getters.currentOrder
      let bonusValue = this.user?.bonus_value
      let shippingPrice = currentOrder.shipping_price
      if (this.freeShipping)
        shippingPrice = 0
      let orderItemsPrice = this.orderTotal - (shippingPrice + currentOrder.payment_price)
      if (bonusValue > orderItemsPrice)
        return parseInt(orderItemsPrice)
      else
        return parseInt(bonusValue)
    },
    removeBonus: function() {
      this.$store.commit('removeBonus')
    },
    useBonus: function() {
      let self = this
      bootbox.prompt({
        locale: self.currentLocale,
        title: self.$t('use_bonus'),
        inputType: 'number',
        callback: function(result) {
          if (result) {
            self.$store.commit('useBonus', parseInt(result))
          }
        },
      })
      this.$nextTick(() => {
        let input = document.querySelector('.bootbox-input')
        let max = self.getUsableBonus()
        input.value = max
        input.setAttribute('min', 0)
        input.setAttribute('max', max)
        input.setAttribute('step', 1)
        input.onchange = function() {
          if (input.value < 0 || input.value > max) {
            input.classList.add('is-invalid')
          } else {
            input.classList.remove('is-invalid')
          }
        }
      })
    },
    removePromoCode: function() {
      this.$store.commit('removePromo')
    },
    usePromoCode: function() {
      let self = this
      let store = this.$store
      bootbox.prompt({
        locale: self.currentLocale,
        title: self.$t('use_promo_code'),
        inputType: 'text',
        callback: function(result) {
          if (result) {
            self.checkPromoValidity(result).then((it) => {
              if (it.valid) {
                store.commit('usePromo', {
                  hash: result,
                  value: it.value,
                })
              }
            })
          }
        },
      })
    },
    processTermsLabelText: function() {
      MetaRepository.getInstance().getMeta().then((response) => {
        this.$refs.terms_check_label.innerHTML = this.$t('terms_check')
            .replace('__TERMS_LINK__', response.data.terms_of_service_page_link)
            .replace('__PRIVACY_LINK__', response.data.privacy_policy_page_link)
      })
    },
    trackAnalyticsPurchase() {
      window.analyticsTrackEvent('Purchase', {
        'content_ids': this.items.map((i) => i.id),
        'content_name': 'Purchase',
        'content_type': 'purchase',
        'contents': RxUtils.rxToObject(this.items),
        'currency': this.currency,
        'num_items': this.itemCount(),
        'value': this.orderTotal
      })
    },
  },
  computed: {
    shippingItem: function() {
      let price = 0
      if (!this.freeShipping) {
        price = this.getCurrentOrder().shipping_price
      }
      let description = this.getShippingTypes()
          .find((it) => it.id === this.getCurrentOrder().shipping_type_id).title
      return {
        title: this.$t('shipping'),
        id: description,
        count: 1,
        price: price,
        price_with_vat: price,
        price_before_free_shipping: this.getCurrentOrder().shipping_price,
      }
    },
    paymentItem: function() {
      let description = this.getPaymentTypes()
          .find((it) => it.id === this.getCurrentOrder().payment_type_id).title
      return {
        title: this.$t('payment'),
        id: description,
        count: 1,
        price: this.getCurrentOrder().payment_price,
        price_with_vat: this.getCurrentOrder().payment_price,
      }
    },
    bonusItem: function() {
      if (this.getCurrentOrder().bonus_value != null &&
          this.getCurrentOrder().bonus_value !== 0) {
        return {
          title: this.$t('bonus_rate'),
          id: '',
          count: 1,
          price: -this.getCurrentOrder().bonus_value,
          price_with_vat: -this.getCurrentOrder().bonus_value,
        }
      } else return null
    },
    promoItem: function() {
      if (this.getCurrentOrder().promo_code != null) {
        return {
          title: this.$t('promo_code'),
          id: this.getCurrentOrder().promo_code,
          count: 1,
          price: -this.getCurrentOrder().promo_value,
          price_with_vat: -this.getCurrentOrder().promo_value,
        }
      } else return null
    },
  },
  watch: {
    currentLocale: function(newVal, oldVal) {
      this.processTermsLabelText()
    },
  },
  mounted() {
    this.processTermsLabelText()
    if (this.getCurrentOrder() == null) {
      this.$router.push('/cart')
    } else {
      this.loading(true)
      this.loadCart()
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../theme/theme";

.order.overview {
  .terms-check {
    label {
      font-size: 14px;
    }
  }

  .btn {
    .money_format {
      display: inline-block;
    }
  }

  .bonus-item-wrapper, .promo-item-wrapper {
    position: relative;

    .btn-remove {
      position: absolute;
      bottom: 8px;

      @include media-breakpoint-up(lg) {
        right: 8px;
      }

      @include media-breakpoint-down(md) {
        left: 8px;
      }
    }
  }
}
</style>