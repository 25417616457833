<template>
  <span :ref="'label'"></span>
</template>

<script>
import moment from 'moment'

export default {
  name: 'FormattedDate',
  props: {
    date: String,
  },
  methods: {
    process: function() {
      moment.locale(this.currentLocale)
      let date = moment(this.date)
      this.$refs['label'].innerHTML = `${date.calendar()}`
    },
  },
  watch: {
    currentLocale: function() {
      this.process()
    },
  },
  mounted() {
    this.process()
  },
}
</script>