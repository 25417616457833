<template>
  <div class="account-created container">
    <div class="row justify-content-center">
      <div class="col-4">
        <div class="card mb-4">
          <div class="card-body">
            <h1>{{ $t('account_created') }}</h1>
            <a :href="continueLink" class="btn btn-primary">{{ $t('continue') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AccountCreated',
  computed: {
    continueLink: function() {
      let redirect = this.$route.query.redirect
      if (redirect) {
        return redirect
      } else {
        return '/'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.account-created {
  margin-top: 60px;
  margin-bottom: 60px;
}
</style>
