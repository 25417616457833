import store from '@/store'
import BaseRepository from './BaseRepository.js'
import Log from '../utils/logger'

export default class CartRepository extends BaseRepository {
  async getCart() {
    return this.get('/carts')
  }

  async addProductToCart(productId, variant) {
    Log.d('addProductToCart', productId)
    return this.post('/carts/addProduct', {
      product_id: productId,
      variant: variant
    }).then((response) => {
      store.commit('updateCart', response.data.cart)
      return response.data.cart
    }).catch((err) => {
      Log.e(err)
    })
  }

  async removeProductFromCart(productId) {
    Log.d('removeProductFromCart', productId)
    return this.post('/carts/removeProduct', {
      product_id: productId,
    }).then((response) => {
      store.commit('updateCart', response.data.cart)
      return response.data.cart
    }).catch((err) => {
      Log.e(err)
    })
  }

  async emptyCart() {
    return this.post('/carts/empty')
      .then((response) => {
        store.commit('updateCart', response.data.cart)
        return response.data.cart
      })
      .catch((err) => {
        Log.e(err)
      })
  }
}