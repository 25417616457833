<template>
  <div class="total card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-5 col-4" :class="{'col-md-7': !isCart, 'col-5': !isCart}">
          <span class="total-label">{{ $t('cart_total') }}</span>
        </div>
        <div class="col-md-3 col-4 text-lg-right">
          <span class="count" v-if="count == 1">{{ count }} {{ $t('item') }}</span>
          <span class="count" v-if="count > 1">{{ count }} {{ $t('items') }}</span>
        </div>
        <div class="col-md-2 col-2 text-lg-right">
          <div class="price total">
            <money-format :value="price"
                          :locale='currentLocale'
                          :currency-code='currency'
                          :hide-subunits='true'/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MoneyFormat from 'vue-money-format';

export default {
  name: 'CartTotal',
  props: {
    'count': Number,
    'price': Number,
    'isCart': Boolean
  },
  components: {
    'money-format': MoneyFormat,
  }
}
</script>

<style lang="scss" scoped>
@import "../theme/theme";

.total-label, .price, .count, {
  color: black;
  font-size: 16px;
  line-height: 22px;
  font-family: $font-family-base;

  @include media-breakpoint-up(md) {
    font-size: 20px;
    line-height: 30px;
  }

  &.total {
    color: $primary;
  }

  .money_format {
    display: inline-block;
  }
}
</style>