export default class Strings {
  static stripHTML(str) {
    const parsedHTML = new DOMParser().parseFromString(str, 'text/html')
    const text = parsedHTML.body.textContent

    if (/(<([^>]+)>)/gi.test(text)) {
      return this.stripHTML(text)
    }

    return text || ''
  }
}