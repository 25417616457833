<template>
  <div class="labels text-right" v-if="product">
    <div class="bookmark-label orange"
         v-if="hasFreeShipping(product)">
      <span class="label">{{ $t('free_shipping') }}</span>
      <span class="bookmark orange"></span>
    </div>
    <div class="bookmark-label warning"
         v-if="lastItemInStock">
      <span class="label">{{ $t('last_piece') }}</span>
      <span class="bookmark warning"></span>
    </div>
    <div class="bookmark-label blue"
         v-if="product.gift">
      <span class="label">{{ $t('gift') }}</span>
      <span class="bookmark blue"></span>
    </div>
    <div class="discount" v-if="product.discount > 0">
      -{{ product.discount }}%
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProductLabels',
  props: {
    "product": Object
  },
  computed: {
    lastItemInStock: function() {
      return this.product.stock === 1 || this.product.products_stock_count === 1
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../theme/theme";

.labels {
  pointer-events: none;
  position: absolute;
  font-family: $font-family-base;
  font-weight: bold;
  right: 0;
  top: 25px;

  .discount {
    float: right;
    width: 60px;
    height: 60px;
    background: $primary;
    border-radius: 100%;
    color: white;
    line-height: 60px;
    text-align: center;
    font-size: 20px;
  }

  .bookmark-label {
    margin-bottom: 4px;
    max-height: 30px;
    overflow: hidden;

    &.orange {
      .label {
        background: $primary;
      }
    }

    &.black {
      .label {
        background: black;
      }
    }

    &.blue {
      .label {
        background: $blue;
      }
    }

    &.warning {
      .label {
        background: $danger;
      }
    }

    .label {
      color: white;
      text-transform: uppercase;
      font-size: 14px;
      padding: 4.5px;
    }

    .bookmark {
      position: relative;
      height: 26px;
      width: 10px;
      padding: 0;
      margin-left: -3px;
      -webkit-transform: rotate(0deg) skew(0deg);
      transform: rotate(-90deg) skew(0deg);
      border-bottom: 10px solid transparent;
      display: inline-block;
      margin-bottom: -9px;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;

      &.orange {
        border-left: 13px solid $primary;
        border-right: 13px solid $primary;
      }

      &.black {
        border-left: 13px solid black;
        border-right: 13px solid black;
      }

      &.blue {
        border-left: 13px solid $blue;
        border-right: 13px solid $blue;
      }

      &.warning {
        border-left: 13px solid $danger;
        border-right: 13px solid $danger;
      }
    }
  }
}
</style>