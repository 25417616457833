import BaseRepository from './BaseRepository.js'

export default class PromoCodesRepository extends BaseRepository {

  async checkPromoValidity(hash) {
    let response = await this.post('promo/check', {
      'hash': hash
    })
    return response.data
  }

  async resentEmail(promoCodeId, email) {
    return this.post('promo/resent-email', {
      'promo_code_id': promoCodeId,
      'email': email
    })
  }
}