import Vue from 'vue'

Vue.mixin({
  computed: {
    deliveryTypes: function() {
      return {
        'basic': 0,
        'zasilkovna': 1,
      }
    },
  },
})