import store from '../store'
import BaseRepository from './BaseRepository.js'
import Log from '../utils/logger'
import * as _ from 'lodash'

export default class CartRepository extends BaseRepository {
  async getCategories() {
    this.get('/categories')
      .then((response) => {
        let processedCategories = _.sortBy(_.map(response.data.items, (it) => {
          if (!it.sort) {
            it.sort = 99999
          }
          return it
        }), 'sort');
        store.commit('updateCategories', processedCategories)
      })
      .catch((err) => {
        Log.e('getCategories', err)
      })
  }
}