import Vue from 'vue'
import CategoriesRepository from '../repository/CategoriesRepository'
import * as _ from 'lodash'

Vue.mixin({
  methods: {
    breadcrumbsFromCategory: function(categoryId) {
      if (categoryId == null) return null
      let category = this.categories.find(c => c.id === categoryId)
      let path = this.constructPath(category, categoryId)
      return path.reverse()
    },
    generateCategoryLink: function(category) {
      return this.constructPath(category, category.id).map((it) => {
        return this.generateCategorySlug(it)
      }).reverse().join('/')
    },
    findParent: function(categories, categoryId) {
      let category = categories.find(c => c.id === categoryId)
      if (category?.parent_id != null)
        return categories.find(c => c.id === category.parent_id)
      else return null
    },
    constructPath: function(category, categoryId) {
      let path = []
      let rootReached = false
      let cId = categoryId
      path.push(category)
      while (!rootReached) {
        let parent = this.findParent(this.categories, cId)
        if (parent != null) {
          cId = parent.id
          path.push(parent)
        } else {
          rootReached = true
        }
      }
      return path
    },
    getChildCategories: function(categoryId) {
      return this.categories.filter(
        category => category.parent_id === categoryId)
    },
    getCategoryByUrl: function(url) {
      let slugs = url.split('/')
      return this.searchInCategoriesTree(this.categoriesTree, slugs)
    },
    searchInCategoriesTree: function(categories, slugs) {
      return this.findBySlug(categories, slugs)
    },
    findBySlug: function(categories, slugs, level = 0) {
      let category = null
      let self = this
      _.forEach(categories, (it) => {
        if (this.generateCategorySlug(it) === slugs[level]) {
          if (slugs.length - 1 === level) {
            category = it
          } else {
            category = self.findBySlug(it.categories, slugs, level + 1)
          }
        }
      })
      return category
    },
    generateCategorySlug: (category) => category.title?.normalize('NFD')
      ?.replace(/[\u0300-\u036f]/g, '')
      ?.replaceAll(' ', '-')
      ?.toLowerCase() ?? '',
  },
  computed: {
    categories() {
      if (this.$store.getters.categories != null) {
        return this.$store.getters.categories
      } else {
        CategoriesRepository.getInstance().getCategories()
        return []
      }
    },
    categoriesTree: function() {
      return this.$store.getters.categoriesTree
    },
  },
})