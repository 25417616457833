<template>
  <div class="address-form">
    <form>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="first_name"><span class="asterisk">*</span>{{ $t('first_name') }}</label>
          <input type="text" class="form-control" id="first_name"
                 v-bind:class="{'is-invalid': (submitted && errors.firstName)}"
                 v-model="firstName">
        </div>
        <div class="form-group col-md-6">
          <label for="last_name"><span class="asterisk">*</span>{{ $t('last_name') }}</label>
          <input type="text" class="form-control" id="last_name"
                 v-bind:class="{'is-invalid': (submitted && errors.lastName)}"
                 v-model="lastName">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="email"><span class="asterisk">*</span>{{ $t('email') }}</label>
          <input type="email" class="form-control" id="email"
                 v-bind:class="{'is-invalid': (submitted && errors.email)}"
                 v-model="email">
        </div>
        <div class="form-group col-md-6">
          <label for="phone"><span class="asterisk">*</span>{{ $t('phone') }}</label>
          <input type="tel" class="form-control" id="phone"
                 v-bind:class="{'is-invalid': (submitted && errors.phone)}"
                 v-model="phone">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="company_name">{{ $t('company_name') }}</label>
          <input type="text" class="form-control" id="company_name"
                 v-model="companyName">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="street"><span class="asterisk">*</span>{{ $t('street') }}</label>
          <input type="text" class="form-control" id="street"
                 v-bind:class="{'is-invalid': (submitted && errors.street)}"
                 v-model="street">
        </div>
        <div class="form-group col-md-6">
          <label for="house_number"><span class="asterisk">*</span>{{ $t('house_number') }}</label>
          <input type="text" class="form-control" id="house_number"
                 v-bind:class="{'is-invalid': (submitted && errors.houseNumber)}"
                 v-model="houseNumber">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="city"><span class="asterisk">*</span>{{ $t('city') }}</label>
          <input type="text" class="form-control" id="city"
                 v-bind:class="{'is-invalid': (submitted && errors.city)}"
                 v-model="city">
        </div>
        <div class="form-group col-md-6">
          <label for="zip"><span class="asterisk">*</span>{{ $t('zip') }}</label>
          <input type="text" class="form-control" id="zip"
                 v-bind:class="{'is-invalid': (submitted && errors.zip)}"
                 v-model="zip">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="state">{{ $t('state') }}</label>
          <input type="text" class="form-control" id="state"
                 v-bind:class="{'is-invalid': (submitted && errors.state)}"
                 v-model="state">
        </div>
        <div class="form-group col-md-6">
          <label for="country"><span class="asterisk">*</span>{{ $t('country') }}</label>
          <select class="custom-select" required id="country" v-model="country"
                  v-bind:class="{'is-invalid': (submitted && errors.country)}">
            <option value=""></option>
            <template v-for="country in countryList">
              <option :value="country.code.toLowerCase()"
                      v-bind:key="country.code"
                      selected="">
                {{ country.name }}
              </option>
            </template>
          </select>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import countries from 'countries-list'
import RxUtils from '../utils/rx_utils'
import _ from 'lodash'
import Validation from '../utils/validation'
import Log from '@/utils/logger'
import LocalDataRepository from '@/repository/LocalDataRepository'

export default {
  name: 'AddressForm',
  props: { 'type': String, 'prefill': Boolean },
  data() {
    return {
      countryList: [],
      id: null,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      companyName: '',
      street: '',
      houseNumber: '',
      city: '',
      zip: '',
      state: '',
      country: '',
      submitted: false,
      valid: true,
      errors: {
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
        companyName: false,
        street: false,
        houseNumber: false,
        city: false,
        zip: false,
        state: false,
        country: false,
      },
    }
  },
  methods: {
    getData() {
      return {
        "id": this.id,
        "type": this.type,
        "first_name": this.firstName,
        "last_name": this.lastName,
        "email": this.email,
        "phone": this.phone,
        "company_name": this.companyName,
        "street": this.street,
        "house_number": this.houseNumber,
        "city": this.city,
        "zip": this.zip,
        "state": this.state,
        "country": this.country
      }
    },
    validateEmpty(item) {
      if (_.isEmpty(this[item])) {
        this.valid = false
        this.errors[item] = true
      }
    },
    validate() {
      this.valid = true
      this.submitted = false
      for (let key in this.errors) {
        this.errors[key] = false
      }
      if (!Validation.validateEmail(this.email)) {
        this.errors.email = true
        this.valid = false
      }
      this.validateEmpty('firstName')
      this.validateEmpty('lastName')
      this.validateEmpty('phone')
      this.validateEmpty('street')
      this.validateEmpty('houseNumber')
      this.validateEmpty('city')
      this.validateEmpty('country')
      this.validateEmpty('zip')
      this.submitted = true
      return this.valid
    },
  },
  mounted() {
    const countryCodes = Object.keys(countries.countries)
    this.countryList = countryCodes.map(code => ({
      code: code,
      name: countries.countries[code].name,
    }))
    if (this.prefill) {
      let address = null
      let localAddresses = LocalDataRepository.getInstance().loadLocalAddresses()
      let currentOrder = this.getCurrentOrder();
      if (currentOrder != null
          && (currentOrder.billing_address != null || currentOrder.shipping_address != null)) {
        if (this.type === "billing") {
          address = this.getCurrentOrder().billing_address;
        } else if (this.type === "shipping") {
          address = this.getCurrentOrder().shipping_address;
        }
      } else if (this.isAuthenticated()) {
        address = this.user.addresses.find(it => it.type === this.type)
      } else if (!_.isEmpty(localAddresses)) {
        address = localAddresses.find(it => it.type === this.type)
      }
      if (address != null) {
        this.id = address.id
        this.firstName = address.first_name
        this.lastName = address.last_name
        this.email = address.email
        this.phone = address.phone
        this.companyName = address.company_name
        this.street = address.street
        this.houseNumber = address.house_number
        this.city = address.city
        this.zip = address.zip
        this.state = address.state
        this.country = address.country
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  .asterisk {
    font-size: 12px;
    color: red;
    margin-right: 8px;
  }
</style>