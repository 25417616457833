<template>
  <div class="page">
    <div class="container" v-html="page.page_data['gjs-html']">
    </div>
  </div>
</template>

<script>

import PagesRepository from '@/repository/PagesRepository'

export default {
  name: 'Page',
  data: function() {
    return {
      page: null,
    }
  },
  mounted() {
    this.scrollToTop()
    PagesRepository.getInstance().getPage(this.$route.params.id)
        .then((response) => {
          this.page = response.data
          document.head.insertAdjacentHTML('beforeend',
              `<style>${response.data.page_data['gjs-css']}</style>`)
        })
        .catch((error) => {
          this.notifyError(error.message)
        })
  },
}
</script>

<style lang="scss" scoped>
.page {
  margin-top: 60px;
}
</style>