import Vue from 'vue'
import CartRepository from '../repository/CartRepository'
import ProductsRepository from '../repository/ProductsRepository'
import Log from '@/utils/logger'
import LocalDataRepository from '@/repository/LocalDataRepository'
import * as _ from 'lodash'
import OrdersRepository from '@/repository/OrdersRepository'
import RxUtils from '@/utils/rx_utils'

Vue.mixin({
  methods: {
    async addProductToCart(productId, variant) {
      let sameAlreadyAddedProduct = this.cart().find((it) => it.id === productId)
      if (sameAlreadyAddedProduct?.count >= sameAlreadyAddedProduct?.stock) {
        this.notifyError(this.$t('add_product_out_of_stock_message'))
      } else {
        let cartRepository = CartRepository.getInstance()
        let localDataRepository = LocalDataRepository.getInstance()
        await this.auth(() => cartRepository.addProductToCart(productId, variant),
            () => localDataRepository.addProductToLocalCart(productId, variant))
            .then(() => {
              this.notifyProductAddedToCart()
              this.trackAnalyticsAddToCart(productId, variant)
            })
            .catch((e) => {
              Log.e('addProductToCart', e)
              this.notifySomethingWrongHappened()
            })
      }
    },
    async removeProductFromCart(productId) {
      let cartRepository = CartRepository.getInstance()
      let localDataRepository = LocalDataRepository.getInstance()
      this.auth(() => cartRepository.removeProductFromCart(productId),
          () => localDataRepository.removeProductFromLocalCart(productId))
          .then(() => this.notifyProductRemovedFromCart())
          .catch(() => this.notifySomethingWrongHappened())
    },
    async trackAnalyticsAddToCart(productId, variant) {
      let response = await ProductsRepository.getInstance().getProduct(productId)
      window.analyticsTrackEvent('AddToCart', {
        'content_ids': productId,
        'content_name': response.data.title,
        'content_type': 'Product',
        'currency': this.currency,
        'value': response.data.price,
      })
    },
    async getCompleteCart() {
      let items = []
      let requests = []
      this.cart().forEach((item) => {
        requests.push(ProductsRepository.getInstance().getProduct(item.product_id))
      })
      let responses = await Promise.all(requests).catch((exception) => {
        Log.d('getCompleteCart', exception)
      })
      responses.forEach((response) => {
        if (response.data != null) {
          let metaProduct = this.cart().find(item => item.product_id === response.data.id)
          let product = response.data
          product.count = metaProduct.count
          items.push(product)
        }
      })
      let cardProductIds = this.cart().map((it) => it.product_id)
      let completeCardProductIds = items.map((it) => it.id)
      let productIdsToRemove = cardProductIds.filter((it) => {
        if (!completeCardProductIds.includes(it)) return it
      })
      productIdsToRemove.forEach((it) => {
        CartRepository.getInstance().removeProductFromCart(it)
      })
      return items
    },
    cart() {
      if (this.$store?.getters?.user?.cart != null) {
        return this.$store.getters.user.cart
      } else {
        return LocalDataRepository.getInstance().loadLocalCart()
      }
    },
    cartCount() {
      let count = 0
      if (this.cart() != null) {
        this.cart().forEach((item) => {
          count += item.count
        })
      } else {
        let localCart = LocalDataRepository.getInstance().loadLocalCart()
        if (!_.isEmpty(localCart))
          localCart.forEach((item) => {
            count += item.count
          })
      }
      return count
    },
    getShippingTypes() {
      return this.$store.getters.shipping
    },
    getPaymentTypes() {
      return this.$store.getters.payment
    },
    async submitCurrentOrder(callback, errorCallback) {
      let order = this.getCurrentOrder()
      if (this.freeShipping) {
        order.shipping_price = 0
        order.free_shipping = true
      }
      order.price = this.orderTotal
      OrdersRepository.getInstance().createOrder(RxUtils.rxToObject(order))
          .then((result) => {
            if (this.isAuthenticated())
              CartRepository.getInstance().emptyCart()
            LocalDataRepository.getInstance().emptyLocalCart()
            let orderHash = result.data.order.hash
            this.trackPurchase(result.data.order)
            this.$store.commit('updateCurrentOrder', null)
            if (callback != null) callback(orderHash)
          })
          .catch((err) => {
            Log.e('nextStep', err)
            this.notifyError(err)
            if (errorCallback != null) errorCallback(err)
          })
    },
    trackPurchase(order) {
      Log.d('order', order)
      let orderEvent = {
        'value': order.price,
        'currency': 'czk',
        'shipping': order.shipping_price,
        'items': _.map(order.order_items, function (item) {
          return {
            'id': item.product_id,
            'name': item.product_imprint.title,
            'quantity': item.count,
            'price': item.price,
          }
        }),
      }
      Log.d('order event data', orderEvent)
      if (!window.isDebug() && window.consentGiven()) {
        this.$gtag.event('purchase', orderEvent)
      } else {
        this.$notify({
          title: 'Tracking GTag event: purchase',
          text: orderEvent,
        })
      }
    },
  },
  computed: {
    freeShipping: function () {
      let order = this.$store.getters.currentOrder
      let price = 0
      order.order_items.forEach((it) => price += it.count * this.processPrice(it))
      let freeShippingPriceReached = this.$store.getters.productsMeta.free_shipping_price < price

      let shippingAddress = this.getCurrentOrder().shipping_address
      let shippingToCountry = shippingAddress.country
      let freeShippingCountrySelected = this.$store.getters.productsMeta.free_shipping_countries.includes(shippingToCountry)

      return freeShippingPriceReached && (freeShippingCountrySelected || this.$store.getters.productsMeta.free_shipping_countries.length === 0)
    },
    orderTotal: function () {
      let order = this.$store.getters.currentOrder
      let price = 0

      if (order != null) {
        order.order_items.forEach((it) => price += it.count * this.processPrice(it))

        if (order.bonus_value != null) {
          price -= order.bonus_value
        }

        let selectedShippingType = this.getShippingTypes().find((it) => it.id == order.shipping_type_id)
        if (selectedShippingType != null && !this.freeShipping)
          price += selectedShippingType.price

        let selectedPaymentType = this.getPaymentTypes().find((it) => it.id == order.payment_type_id)
        if (selectedPaymentType != null)
          price += selectedPaymentType.price

        if (order.promo_value != null) {
          if (order.promo_value <= price) {
            price -= order.promo_value
          } else {
            price = 0
          }
        }
      }

      return price
    },
  },
  asyncComputed: {
    async cartTotal() {
      let total = 0
      let cart = await this.getCompleteCart()
      if (cart != null) {
        cart.forEach((item) => {
          total += this.processPrice(item) * item.count
        })
      }
      return total
    },
  },
})