<template>
  <div class="reset-password container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-5 col-xl-4">
        <div class="card mb-4">
          <div class="card-body">
            <h1>{{ $t('forgot_password') }}</h1>
            <div class="form-group">
              <label for="email">{{ $t('email') }}</label>
              <input type="text"
                     id="email"
                     name="email"
                     required
                     class="form-control"
                     :class="{'is-invalid': validated && (input.email.trim().length === 0 || invalidLogin)}"
                     v-model="input.email"/>
              <div>
                <div class="invalid-feedback"
                     :class="{'d-block': validated && input.email.trim().length === 0}">
                  {{ $t('is_required') }}
                </div>
                <div class="invalid-feedback"
                     :class="{'d-block': validated && invalidLogin && input.email.trim().length > 0}">
                  {{ $t('invalid_login') }}
                </div>
                <div class="valid-feedback" :class="{'d-block': submitted}">
                  {{ $t('reset_password_email_sent') }}
                </div>
              </div>
            </div>
            <button type="button"
                    class="btn btn-primary float-right"
                    v-on:click="forgotPassword()">{{ $t('submit') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserRepository from '@/repository/UserRepository.js'
import Log from '@/utils/logger'

export default {
  name: 'ForgotPassword',
  data() {
    return {
      validated: false,
      invalidLogin: false,
      submitted: false,
      input: {
        email: '',
      },
    }
  },
  methods: {
    forgotPassword() {
      this.validated = true
      this.submitted = false
      if (this.validated && this.input.email !== '') {
        UserRepository.getInstance().forgotPassword(this.input.email)
            .then((response) => {
              this.submitted = true
            })
            .catch((error) => {
              this.invalidLogin = true
            })
      } else {
        this.invalidLogin = false
      }
    },
  },
  watch: {
    'input.password': function(oldValue, newValue) {
      if (this.validated && this.invalidLogin) {
        this.invalidLogin = false
        this.submitted = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.reset-password {
  margin-top: 60px;
  margin-bottom: 60px;
}
</style>
