import store from '@/store'
import BaseRepository from './BaseRepository.js'
import Log from '@/utils/logger'

export default class BannersRepository extends BaseRepository {
  async getBanners() {
    return this.get('/banners')
      .then((response) => {
        store.commit('updateBanners', response.data.items
          .filter((it) => { return it.active === 1}))
      })
      .catch((err) => {
        Log.e(err)
      })
  }
}