import store from '../store'
import BaseRepository from './BaseRepository.js'
import Log from '../utils/logger'

export default class ProductsRepository extends BaseRepository {

  productsCache = {}

  async getProducts(
    page = 0,
    perPage = 30,
    categoryIds = null,
    priceLow = null,
    priceHigh = null,
    manufacturerId = null,
    sort = null,
  ) {
    if (categoryIds != null)
      categoryIds = `&categories=${categoryIds}`
    else categoryIds = ''

    if (priceLow != null)
      priceLow = `&price_low=${priceLow}`
    else priceLow = ''

    if (priceHigh != null)
      priceHigh = `&price_high=${priceHigh}`
    else priceHigh = ''

    if (manufacturerId != null)
      manufacturerId = `&manufacturer=${manufacturerId}`
    else manufacturerId = ''

    if (sort != null)
      sort = `&sort=${sort}`
    else sort = ''

    let query = `/products/filter?page=${page}&per_page=${perPage}${categoryIds}${priceLow}${priceHigh}${manufacturerId}${sort}`
    Log.d('query', query)

    return this.get(query)
  }

  async getProduct(productId) {
    let escapedProductId = productId.replaceAll('&', '%26')
    if (this.productsCache[escapedProductId] == null) {
      let response = await this.get(`/products/item/?id=${escapedProductId}`)
      this.productsCache[escapedProductId] = response.data
    }
    return { data: this.productsCache[escapedProductId] }
  }

  async getMeta() {
    return this.get('/products/meta').then((response) => {
      store.commit('updateProductsMeta', response.data)
    }).catch((e) => {
      Log.e('getMeta', e)
    })
  }

  async search(page = 0, perPage = 30, search = '') {
    return this.get(
      `/products/search/?page=${page}&per_page=${perPage}&search=${search}`)
  }

  clearCache() {
    this.productsCache = {}
  }
}