<template>
  <div class="topbar-buttons">
    <div class="dropdown">
      <button type="button"
              class="btn dropdown-toggle dropdown-toggle-split"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
              v-html="currentLocale == 'cs' ? 'cz' : 'en'">
      </button>
      <div class="dropdown-menu">
        <a class="dropdown-item" v-on:click="switchLocale('cs')">cz</a>
        <a class="dropdown-item" v-on:click="switchLocale('en')">en</a>
      </div>
    </div>
    <router-link class="icon" :to="user != null ? '/account' : '/login'">
      <img src="/assets/nav-ico-client.svg" class="nav-ico tr-250"/>
    </router-link>
    <router-link class="icon" to="/search">
      <img src="/assets/nav-ico-search.svg" class="nav-ico tr-250"/>
    </router-link>
    <router-link to="/cart" class="icon cart">
      <img src="/assets/nav-ico-cart.svg" class="nav-ico tr-250"/>
      <span v-if="cartCount() > 0" class="count">{{ cartCount() }}</span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'TopBarButtons',
}
</script>

<style lang="scss" scoped>
@import "src/theme/theme";

.topbar-buttons {
  @include media-breakpoint-up(lg) {
    min-width: 270px;
  }

  .dropdown {
    display: inline-block;
    border: none;
    width: 60px;

    button {
      font-family: $font-family-base;
    }

    .dropdown-menu {
      width: 30px;
      overflow: hidden;
    }
  }

  @include media-breakpoint-down(md) {
    .icon {
      img {
        margin-left: 0;
        transform: scale(0.7);
      }
    }
  }
}
</style>