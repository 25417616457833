<template>
  <div class="account container">
    <div class="row">
      <div class="col">
        <AccountNavigation :active="'account'"/>
        <div class="card mb-4">
          <div class="card-header">
            <h3>{{ $t('account') }}: {{ user.first_name }} {{ user.last_name }}
              <span class="small">{{ user.email }}</span>
            </h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col">
                <h4>{{ $t('bonus_rate') }}</h4>
                <p>{{ $t('you_can_spend') }}:
                  <money-format v-if="!this.freeShippingProp"
                                :value="user.bonus_value || 0"
                                :locale='currentLocale'
                                :currency-code='currency'
                                :hide-subunits='true'/>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <span class="btn btn-secondary mr-4" v-on:click="openSettings()">
                  {{ $t('settings') }}
                </span>
                <span class="btn btn-primary" v-on:click="logout()">
                  {{ $t('logout') }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="addresses card" v-if="user.addresses">
          <div class="card-header">
            <h4>{{ $t('address') }}</h4>
          </div>
          <div class="card-body pb-0">
            <div class="row">
              <div class="col-md-6" v-for="address in user.addresses" :key="address.id">
                <AddressListing :address="address"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountNavigation from '@/components/account/AccountNavigation'
import UserRepository from '@/repository/UserRepository'
import AddressListing from '@/components/AddressListing'
import MoneyFormat from 'vue-money-format'

export default {
  name: 'Account',
  components: {
    AccountNavigation,
    AddressListing,
    'money-format': MoneyFormat,
  },
  methods: {
    openSettings: function() {
      this.$router.push('/account-settings')
      this.scrollToTop()
    },
  },
  mounted() {
    UserRepository.getInstance().getUser()
  },
}
</script>

<style lang="scss" scoped>
.account {
  padding-top: 60px;
  padding-bottom: 60px;

  .money_format {
    display: inline-block;
  }
}
</style>
