<template>
  <div class="order address container">
    <div class="row">
      <div class="col">
        <h1>{{ $t('order') }} > {{ $t('address') }}</h1>
        <h2>{{ $t('shipping_address') }}</h2>
        <AddressForm :prefill="true" :type="'shipping'" ref="shippingAddress"/>
        <div class="form-group ml-4 mt-4">
          <input id="billing_address_differs"
                 class="form-check-input"
                 type="checkbox"
                 v-model="billingAddressDiffers"/>
          <label class="form-check-label"
                 for="billing_address_differs">
            {{ $t('billing_address_differs') }}
          </label>
        </div>
        <div v-bind:class="{ collapse: !billingAddressDiffers }">
          <h2>{{ $t('billing_address') }}</h2>
          <AddressForm :prefill="true" :type="'billing'" ref="billingAddress"/>
        </div>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col">
        <div class="mt-4">
          <router-link :to="'/cart'" class="btn btn-secondary">
            {{ $t('back') }}
          </router-link>
          <a v-on:click="nextStep()" class="btn btn-primary float-right">
            {{ $t('continue') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddressForm from '../../components/AddressForm'
import LocalDataRepository from '@/repository/LocalDataRepository'
import Log from '@/utils/logger'

const snakeCaseKeys = require('snakecase-keys')

export default {
  name: 'OrderAddress',
  components: {
    AddressForm,
  },
  data() {
    return {
      billingAddressDiffers: false,
    }
  },
  methods: {
    validate() {
      let shippingAddressComp = this.$refs.shippingAddress
      let billingAddressComp = this.$refs.billingAddress
      if (this.billingAddressDiffers) {
        return shippingAddressComp.validate() && billingAddressComp.validate()
      } else {
        return shippingAddressComp.validate()
      }
    },
    nextStep: function() {
      let shippingAddressComp = this.$refs.shippingAddress
      let billingAddressComp = this.$refs.billingAddress
      if (!this.validate()) {
        this.notifyFormNotValid()
      } else {
        let shippingAddress = shippingAddressComp.getData()
        let billingAddress = billingAddressComp.getData()
        let order = this.getCurrentOrder();
        order['shipping_address'] = snakeCaseKeys(shippingAddress)
        if (!this.isAuthenticated()) {
          LocalDataRepository.getInstance()
              .saveLocalShippingAddress(snakeCaseKeys(shippingAddress))
        }
        if (this.billingAddressDiffers) {
          order['billing_address'] = snakeCaseKeys(billingAddress)
          if (!this.isAuthenticated()) {
            LocalDataRepository.getInstance()
                .saveLocalBillingAddress(snakeCaseKeys(billingAddress))
          }
        }
        Log.d("order", order)
        this.$store.commit('updateCurrentOrder', order)
        this.$router.push('/orders/create/shipping')
        this.scrollToTop()
      }
    },
  },
  mounted() {
    if (this.getCurrentOrder() == null) {
      this.$router.push('/cart')
      this.scrollToTop()
    }
  }
}
</script>

<style lang="scss" scoped>
.order.address {
  padding-top: 60px;
  padding-bottom: 60px;
}
</style>