export const ENGLISH_TRANSLATIONS = {
  yes: 'Yes',
  no: 'No',
  ok: 'Ok',
  login: 'Login',
  home: 'Home',
  search: 'Search',
  date: 'Date',
  manufacturers: 'Manufacturers',
  products: 'Products',
  cart: 'Cart',
  add_to_cart: 'Add to cart',
  previous: 'Previous',
  next: 'Next',
  cancel_filter: 'Cancel filter',
  logout: 'Logout',
  items_in_cart: 'Items in cart',
  username: 'First and lastname',
  password: 'Password',
  categories: 'Categories',
  cart_total: 'Total',
  account: 'Account',
  orders: 'Orders',
  create_order: 'Create order',
  product_added_to_cart: 'Product added to cart',
  product_removed_from_cart: 'Product removed from cart',
  something_wrong_happened: 'Something wrong happened',
  user_unauthorized: 'You are not authorized',
  order: 'Order',
  address: 'Address',
  shipping_address: 'Shipping address',
  billing_address: 'Billing address',
  logout_question: 'Logout?',
  first_name: 'First name',
  last_name: 'Last name',
  email: 'Email',
  phone: 'Phone',
  company_name: 'Company name',
  street: 'Street',
  house_number: 'House number',
  city: 'City',
  zip: 'ZIP',
  state: 'State',
  country: 'Country',
  billing: 'Billing',
  billing_address_differs: 'Billing address differs',
  last_modified: 'Latest',
  recommended: 'Recommended',
  highest_price: 'Highest price',
  lowest_price: 'Lowest price',
  rating: 'Rating',
  order_items: 'Order items',
  free_shipping: 'Free shipping',
  free_shipping_text: 'Free shipping, when ordering over __FREE_SHIPPING_PRICE__ CZK',
  free_shipping_countries_text: 'Only applicable to selected countries (__FREE_SHIPPING_COUNTRIES__)',
  action: 'Action',
  gift: 'Gift',
  filter: 'Filter',
  filters: 'Filters',
  price: 'Price',
  money_back: 'Money back',
  money_back_text: 'If you are not satisfied, we will refund your money',
  support: 'Support',
  support_text: 'If you have any questions, do not hesitate to call',
  item: 'item',
  is_required: 'Is required',
  invalid_login: 'Invalid username or password',
  subcategories: 'Subcategories',
  sort_by: 'Sort by',
  variants: 'Variants',
  please_select_product_variant: 'Please select product variant',
  start_shopping: 'Start shopping',
  more_categories: 'More categories',
  newsletter_signup: 'Newsletter sign-up',
  stay_in_the_know: 'Stay in the know',
  sign_up_for_email: 'Sign up for email',
  customer_support: 'Customer support',
  about_us: 'About us',
  services: 'Services',
  items_count: 'Items count',
  continue: 'Continue',
  back: 'Back',
  continue_without_account: 'Continue without account?',
  create_account: 'Create account',
  account_created: 'Account created',
  forgot_password: 'Forgot password',
  reset_password: 'Reset password',
  reset_password_email_sent: 'Email for resetting password was sent',
  password_reset_successful: 'Password reset successful',
  invalid_link: 'Invalid link',
  shipping: 'Shipping',
  order_state: 'Order state',
  pending: 'Pending',
  payed: 'Payed',
  payment: 'Payment',
  overview: 'Overview',
  pay_order: 'Pay order',
  form_is_not_valid: 'Form is not valid',
  search_input_length_error: 'Please enter 3 or more characters',
  nothing_found: 'Nothing found',
  in_stock: 'In stock',
  not_in_stock: 'Not in stock',
  variant_not_in_stock: 'Variant not in stock',
  recently_viewed: 'Recently viewed',
  settings: 'Settings',
  save: 'Save',
  delete: 'Delete',
  delete_address: 'Delete address?',
  ic: 'IČ',
  dic: 'DIČ',
  page_not_found: 'Page not found',
  add_to_wishlist: 'Add to wishlist',
  remove_from_wishlist: 'Remove from wishlist',
  wishlist_unauthorized_message: 'To add to wishlist you need to be logged in.',
  product_added_to_wishlist: 'Product added to wishlist',
  product_removed_from_wishlist: 'Product removed from wishlist',
  cancel: 'Cancel',
  wishlist: 'Wishlist',
  waiting_for_payment_gateway: 'Waiting for payment gateway response',
  items: 'items',
  submit: 'Submit',
  newsletters_subscription: '<b>I want to receive news and special offers</b><br><p class="small">(You an unsubscribe at any moment).</p>',
  terms_check: 'I Agree with <a href="__TERMS_LINK__" target="_blank">terms and conditions</a> confirm that I have read the <a href="__PRIVACY_LINK__" target="_blank">information about the processing of personal data</a>.',
  password_is_weak: 'Password is weak',
  bonus_rate: 'Bonus rate',
  you_can_spend: 'You can spend',
  use_bonus: 'Use bonus',
  up_to: 'Up to',
  remove_bonus: 'Remove bonus',
  cookie_consent_text: 'This website uses cookies. They have the task of ensuring all your comfort with us.',
  cookie_consent_learn_more: 'Read more',
  order_detail: 'Order detail',
  last_piece: 'Last piece',
  sorry_no_shipping: 'We are sorry, but unfortunately we do not send the goods to your country',
  add_product_out_of_stock_message: 'Cannot add to cart, not in stock anymore',
  ask_about_product: 'Question about product',
  usable_payment_types: 'Usable payment types',
  usable_shipping_types: 'Usable shipping types',
  promo_code: 'Promo code',
  use_promo_code: 'Use promo code',
  remove_promo_code: 'Remove promo code',
  resent_to_email: 'Resent to email',
  close: 'Close',
  selected_pickup_point: 'Selected pickup point',
  select_different_pickup_point: 'Select different pickup point',
}