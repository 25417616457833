<template>
  <router-link v-if="!page.redirect" :to="`/page/${this.page.id}`" :target="pageTarget">
    {{ page.title }}
  </router-link>
  <a v-else :href="page.url" :target="pageTarget">{{ page.title }}</a>
</template>

<script>
export default {
  name: 'PageLink',
  props: {
    'page': Object,
  },
  computed: {
    pageTarget: function() {
      if (this.page.new_window) {
        return '_blank'
      } else {
        return '_self'
      }
    },
  },
}
</script>