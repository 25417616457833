import moment from 'moment'
import jwt from 'jsonwebtoken'
import Log from './logger'

export default class AuthenticationUtils {
  static isValidToken(token) {
    if (!token) {
      return false
    }
    let decoded = null
    try {
      decoded = jwt.verify(token, 'customer_token', {
        algorithms: ['HS512'],
      })
    } catch (e) {
      Log.e('isValidToken', e)
      return false
    }
    if (decoded) {
      let now = moment()
      let expiration = moment(decoded.exp)
      let expired = now.isBefore(expiration)
      return !expired
    }
  }
}