import store from '@/store'
import BaseRepository from '@/repository/BaseRepository'

export default class ShippingRepository extends BaseRepository {
  async getAllShippingTypes() {
    return this.get('/shipping/')
      .then((response) => {
        store.commit('updateShippings', response.data.items)
        return response.data.items
      })
  }
}