<template>
  <div class="container page-not-found text-center">
    <h1>{{ $t('page_not_found') }}</h1>
    <a v-on:click="goBack()" class="btn btn-primary mt-4">{{ $t('back') }}</a>
    <router-link class="btn btn-secondary mt-4 ml-4" to="search">{{ $t('search') }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  methods: {
    goBack: function() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" scoped>
.page-not-found {
  padding-top: 60px;
  padding-bottom: 60px;
}
</style>