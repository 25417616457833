import Vue from 'vue'
import store from '@/store'
import OrdersRepository from '../repository/OrdersRepository'

Vue.mixin({
  methods: {
    getOrders() {
      return OrdersRepository.getInstance().getOrders()
    },
    getCurrentOrder() {
      return store.getters.currentOrder;
    }
  },
})