<template>
  <div class="reset-password container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-5 col-xl-4">
        <div class="card mb-4">
          <div class="card-body">
            <h1>{{ $t('reset_password') }}</h1>
            <div class="form-group">
              <label for="password">{{ $t('password') }}</label>
              <input type="password"
                     name="password"
                     id="password"
                     required
                     class="form-control"
                     :class="{'is-invalid': validated && (input.password.trim().length === 0 || invalidLogin)}"
                     v-model="input.password"/>
              <div>
                <div class="invalid-feedback"
                     :class="{'d-block': validated && input.password.trim().length === 0}">
                  {{ $t('is_required') }}
                </div>
                <div class="invalid-feedback"
                     :class="{'d-block': validated && weakPassword}">
                  {{ $t('password_is_weak') }}
                </div>
                <div class="valid-feedback" :class="{'d-block': submitted && success}">
                  {{ $t('password_reset_successful') }}
                </div>
              </div>
            </div>
            <button type="button"
                    class="btn btn-primary float-right"
                    v-on:click="resetPassword()">{{ $t('reset_password') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserRepository from '@/repository/UserRepository.js'
import Log from '@/utils/logger'
import zxcvbn from 'zxcvbn-i18n'

const jwt = require('jsonwebtoken')

export default {
  name: 'ResetPassword',
  data() {
    return {
      validated: false,
      invalidLogin: false,
      submitted: false,
      success: false,
      weakPassword: false,
      input: {
        password: '',
      },
    }
  },
  methods: {
    resetPassword() {
      this.validated = true
      this.submitted = false
      this.success = false
      if (this.input.password !== '' && this.validatePasswordStrength()) {
        UserRepository.getInstance().resetPassword(
            this.input.password,
            this.$route.query.token,
        ).then((response) => {
          if (response.data.success) {
            this.submitted = true
            this.success = true
            setTimeout(() => {
              window.location.href = '/login'
            }, 3000)
          }
        }).catch((error) => {
          Log.d('error', error)
        })
      } else {
        this.invalidLogin = false
      }
    },
    validatePasswordStrength() {
      let isPasswordStrong = zxcvbn(this.input.password).score >= 3
      this.weakPassword = !isPasswordStrong
      return isPasswordStrong
    },
  },
  watch: {
    'input.password': function(oldValue, newValue) {
      this.weakPassword = false
      if (this.validated && this.invalidLogin) {
        this.invalidLogin = false
      }
    },
  },
  mounted() {
    try {
      let decoded = jwt.verify(this.$route.query.token, 'reset-password')
      Log.d("decoded", decoded)
    } catch (exception) {
      this.$nextTick(() => {
        this.$confirm({
          message: this.$t('invalid_link'),
          button: {
            yes: this.$t('ok'),
          },
          callback: confirm => {
            window.location.href = '/'
          },
        })
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.reset-password {
  margin-top: 60px;
  margin-bottom: 60px;
}
</style>
