<template>
  <div class="recently-viewed" v-if="products">
    <div class="row">
      <div class="col-12">
        <h2>{{ $t('recently_viewed') }}</h2>
      </div>
      <div class="col-lg-3 col-md-4" v-for="item in products" :key="item.id">
        <Product :product="item"/>
      </div>
    </div>
  </div>
</template>

<script>
import Product from '@/components/Product'
import ProductsRepository from '@/repository/ProductsRepository'

export default {
  name: 'RecentlyViewed',
  components: { Product },
  data: function() {
    return {
      products: [],
    }
  },
  methods: {
    load: async function() {
      let productIds = this.loadRecentlyViewedProducts()
      if (productIds == null) return
      let self = this
      productIds.forEach((it) => {
        ProductsRepository.getInstance().getProduct(it).then((it) => {
          self.products.push(it.data)
        })
      })
    },
  },
  mounted() {
    this.load()
  },
}
</script>

<style lang="scss">
.recently-viewed {

}
</style>