<template>
  <div class="search">
    <div class="container mt-4 mb-4">
      <h1>{{ $t('search') }}</h1>
      <div class="input-group mb-3">
        <input type="text" class="form-control" v-model="search"
               v-on:keyup.enter="doSearch()">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" @click="doSearch()" type="button">
            {{ $t('search') }}
          </button>
        </div>
        <div class="invalid-feedback"
             :class="{'d-block': searchInputLengthError}">
          {{ $t('search_input_length_error') }}
        </div>
        <div class="invalid-feedback"
             :class="{'d-block': nothingFound}">
          {{ $t('nothing_found') }}
        </div>
      </div>
    </div>
    <div class="products-grid-wrapper">
      <div class="container">
        <ProductsGrid :page="page" :pages="pages" :products="products"
                      :count="count" :select-page="selectPage"/>

      </div>
    </div>
  </div>
</template>

<script>
import ProductsGrid from '../components/ProductsGrid'
import Log from '../utils/logger'

export default {
  name: 'Search',
  components: {
    ProductsGrid,
  },
  data() {
    return {
      products: [],
      count: 0,
      page: 0,
      pages: 0,
      perPage: 20,
      search: '',
      searchInputLengthError: false,
      loaded: false
    }
  },
  methods: {
    loadPage: function() {
      let query = this.$route.query
      this.search = query.search
      if (this.search.length >= 3) {
        this.searchInputLengthError = false
        this.searchProducts(query.page, query.per_page, query.search)
            .then((response) => {
              this.loaded = true;
              this.products = response.data.items
              this.page = response.data.page
              this.count = response.data.count
              this.pages =
                  (parseInt(response.data.count / response.data.per_page) + 1)
            })
      } else {
        this.searchInputLengthError = true
      }
    },
    doSearch: function() {
      if (this.search === this.$route.query.search) return
      this.products = []
      this.$router.push({
        path: this.$route.path,
        query: Object.assign({},
            this.$route.query,
            {
              page: this.page,
              per_page: this.perPage,
              search: this.search,
            },
        ),
      })
    },
    selectPage: function(page) {
      this.products = []
      this.$router.push({
        path: this.$route.path,
        query: Object.assign({},
            this.$route.query,
            {
              page: page,
              per_page: this.perPage,
              search: this.search,
            },
        ),
      })
    },
  },
  computed: {
    nothingFound: function() {
      return !this.searchInputLengthError && this.products.length === 0 && this.loaded
    },
  },
  watch: {
    '$route.query'() {
      this.loadPage()
    },
  },
  mounted() {
    this.loadPage()
  },
}
</script>

<style lang="scss" scoped="true">
@import "../theme/theme";

.search {
  .products-grid-wrapper {
    background-color: $hd-gray;
  }
}
</style>