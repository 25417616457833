<template>
  <div class="container-full breadcrumbs-hero">
    <div class="container">
      <p class="mb-0">
        <BackendTranslatedText
            :default-value="path[0].title"
            :translation-key="'title'"
            :translations="path[0].translations"/>
      </p>
      <div class="breadcrumbs">
        <div v-if="path.length > 1" class="path">
          <span v-for="(item, index) in path" :key="item.id" class="item">
            <span v-if="index > 1"> > </span>
            <a v-if="index > 0" @click="navigate(item)">
              <BackendTranslatedText
                  :key="item.id"
                  :default-value="item.title"
                  :translation-key="'title'"
                  :translations="item.translations"/>
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import BackendTranslatedText from "@/components/BackendTranslatedText.vue";

export default {
  name: 'Breadcrumbs',
  components: {BackendTranslatedText},
  props: {
    'path': Array,
    navigate: Function
  },
}
</script>

<style lang="scss" scoped>
@import "../theme/theme";

.breadcrumbs-hero {
  background-color: black;
  padding: 25px 0;
  font-size: 20px;
  text-transform: uppercase;
  color: $primary;
  font-family: $font-family-base;

  .breadcrumbs {
    display: inline-block;
    color: white;
    font-size: 35px;
    text-transform: none;

    a {
      color: white;
      font-family: $headings-font-family;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 8%;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    background: url('/assets/grit-edge-right-white.png') no-repeat 0 50%;
    background-size: 100% 100%;
    opacity: 0.3;
  }
}
</style>