import Vue from 'vue'

Vue.mixin({
  methods: {
    notifySaved() {
      this.$notify({
        group: 'notify',
        title: this.$t('saved'),
        type: 'success',
      })
    },
    notifyProductAddedToCart() {
      this.$notify({
        group: 'notify',
        title: this.$t('product_added_to_cart'),
        type: 'success',
      })
    },
    notifyProductAddedToWishlist() {
      this.$notify({
        group: 'notify',
        title: this.$t('product_added_to_wishlist'),
        type: 'success',
      })
    },
    notifyProductRemovedFromCart() {
      this.$notify({
        group: 'notify',
        title: this.$t('product_removed_from_cart'),
        type: 'success',
      })
    },
    notifyProductRemovedFromWishlist() {
      this.$notify({
        group: 'notify',
        title: this.$t('product_removed_from_wishlist'),
        type: 'success',
      })
    },
    notifySomethingWrongHappened() {
      this.$notify({
        group: 'notify',
        title: this.$t('something_wrong_happened'),
        type: 'error',
      })
    },
    notifyUserUnauthorized() {
      this.$notify({
        group: 'notify',
        title: this.$t('user_unauthorized'),
        type: 'error',
      })
    },
    notifyFormNotValid() {
      this.$notify({
        group: 'notify',
        title: this.$t('form_is_not_valid'),
        type: 'error',
      })
    },
    notifyError(error) {
      this.$notify({
        group: 'notify',
        title: error,
        type: 'error',
      })
    },
  },
})