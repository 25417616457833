import packeta from '@/vendor/packeta'
import Log from '@/utils/logger'

window.showPacketaWidget = function(inElement, callback) {
  const packetaApiKey = window.zasilkovnaApiKey
  const packetaOptions = {
    language: 'cs',
    valueFormat: '"Packeta",id,carrierId,carrierPickupPointId,name,city,street',
  }
  if (packeta) try {
    window.Packeta.Widget.pick(packetaApiKey, callback, packetaOptions, inElement)
  } catch (e) {
    Log.e('showPacketaWidget', e)
  }
}

window.hidePacketaWidget = function() {
  if (packeta) try {
    window.Packeta.Widget.hide()
  } catch (e) {
    Log.e('hidePacketaWidget', e)
    document.querySelector('#packeta-widget')?.remove()
  }
}