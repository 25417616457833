<template>
  <div class="address-listing">
    <h3 v-if="address.type === 'shipping'">{{ $t('shipping_address') }}</h3>
    <h3 v-if="address.type === 'billing'">{{ $t('billing_address') }}</h3>
    <p>{{ $t('first_name') }}: <b>{{ address.first_name }}</b></p>
    <p>{{ $t('last_name') }}: <b>{{ address.last_name }}</b></p>
    <p>{{ $t('email') }}: <b>{{ address.email }}</b></p>
    <p>{{ $t('phone') }}: <b>{{ address.phone }}</b></p>
    <p>{{ $t('company_name') }}: <b>{{ address.company_name }}</b>
    </p>
    <p>{{ $t('street') }}: <b>{{ address.street }}</b></p>
    <p>{{ $t('house_number') }}: <b>{{ address.house_number }}</b>
    </p>
    <p>{{ $t('zip') }}: <b>{{ address.zip }}</b></p>
    <p>{{ $t('city') }}: <b>{{ address.city }}</b></p>
    <p>{{ $t('state') }}: <b>{{ address.state }}</b></p>
    <p>{{ $t('country') }}: <b>{{ address.country }}</b></p>
  </div>
</template>

<script>
export default {
  name: 'AddressListing',
  props: {
    "address": Object
  }
}
</script>

<style lang="scss" scoped>

</style>