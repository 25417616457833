import Singleton from '@/utils/Singleton.js'
import Vue from 'vue'
import _ from 'lodash'
import Log from '../utils/logger'
import store from '@/store'
import bootbox from 'bootbox'

export default class BaseRepository extends Singleton {

  constructor() {
    super()
    this.isDevelopment = window.isDebug()
    const axios = require('axios')
    if (this.isDevelopment) {
      this.API_ROOT = window.location.protocol + '//' + window.host() + '/api/v1';
    } else {
      this.API_ROOT = '/api/v1'
    }
    this.DEFAULT_TIMEOUT = 30 * 1000
    this.LONG_TIMEOUT = 60 * 1000
    this.axios = axios.create({
      baseURL: this.API_ROOT,
      timeout: this.DEFAULT_TIMEOUT
    })
    this.axios.interceptors.request.use(request => {
      if (this.isDevelopment) {
        Log.d(`⬆ ${request.url}`, request)
      }
      store.commit('addRequestCount')
      request.headers = {
        'Content-Type': 'application/json',
        'X-Locale': store.getters.locale,
        'Accept': 'application/json',
        'X-Auth-Token': this.getToken(),
      }
      return request
    })
    this.axios.interceptors.response.use(
      response => {
        if (response.code === 503) {
          window.location.reload()
        }
        if (this.isDevelopment) {
          Log.d(`⬇ ${response.config.url}`, response)
        }
        store.commit('removeRequestCount')
        return response
      },
      error => {
        if (this.isDevelopment) {
          Log.e(`⬇ ${error.config.url}`, error)
          Log.e(`⬇ ${error.config.url}`, error.response.data)
          let isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i)
          if (isHTML(error.response.data)) {
            let html = error.response.data
            bootbox.alert({
              title: 'Server error',
              message: '<iframe id="html-server-error"></iframe>',
              size: 'extra-large',
            })
            let iframe = document.querySelector('#html-server-error')
            iframe.src = 'data:text/html,' + encodeURIComponent(html)
          }
        }
        if (error?.response?.data?.message != null) {
          if (window.app.notifyError != null)
            window.app.notifyError(error.response.data.message)
        }
        store.commit('removeRequestCount')
        return error
      })
  }

  getToken() {
    let token = Vue.$cookies.get('token')
    if (!_.isEmpty(token)) {
      return JSON.parse(token)
    }
  }

  async get(url, data) {
    return this.axios.get(url, data)
  }

  async post(url, data) {
    return this.axios.post(url, data)
  }

  async put(url, data) {
    return this.axios.put(url, data)
  }

  async delete(url, data) {
    return this.axios.delete(url, {
      data: data,
    })
  }

}