<template>
  <div class="product-sort dropdown simple">
    <button class="btn btn-secondary dropdown-toggle"
            href="#"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
      {{ $t('sort_by') }}<span v-if="value">: {{ $t(value) }}</span>
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <a class="dropdown-item"
         v-bind:class="{ active: value === 'last_modified' }"
         @click="change('last_modified')">
        {{ $t('last_modified') }}
      </a>
      <a class="dropdown-item"
         v-bind:class="{ active: value === 'recommended' }"
         @click="change('recommended')">
        {{ $t('recommended') }}
      </a>
      <a class="dropdown-item"
         v-bind:class="{ active: value === 'highest_price' }"
         @click="change('highest_price')">
        {{ $t('highest_price') }}
      </a>
      <a class="dropdown-item"
         v-bind:class="{ active: value === 'lowest_price' }"
         @click="change('lowest_price')">
        {{ $t('lowest_price') }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductSort',
  props: {
    value: String,
  },
  methods: {
    change: function(value) {
      this.$emit('change', value)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../theme/theme";

.product-sort {
  width: auto;
  text-transform: none;

  button {
    text-transform: none;
    background: transparent;
  }

  span {
    text-transform: capitalize;
    font-weight: bold;
  }
}
</style>