<template>
  <div class="login container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-5 col-xl-4">
        <div class="card mb-4">
          <div class="card-body">
            <h1>{{ $t('login') }}</h1>
            <div class="form-group">
              <label for="email">{{ $t('email') }}</label>
              <input type="text"
                     id="email"
                     name="email"
                     required
                     class="form-control"
                     v-on:keyup.enter="login()"
                     :class="{'is-invalid': validated && (input.email.trim().length === 0 || invalidLogin)}"
                     v-model="input.email"/>
              <div>
                <div class="invalid-feedback"
                     :class="{'d-block': validated && input.email.trim().length === 0}">
                  {{ $t('is_required') }}
                </div>
                <div class="invalid-feedback"
                     :class="{'d-block': validated && invalidLogin && input.email.trim().length > 0}">
                  {{ $t('invalid_login') }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="password">{{ $t('password') }}</label>
              <input type="password"
                     name="password"
                     id="password"
                     required
                     class="form-control"
                     v-on:keyup.enter="login()"
                     :class="{'is-invalid': validated && (input.password.trim().length === 0 || invalidLogin)}"
                     v-model="input.password"/>
              <div>
                <div class="invalid-feedback"
                     :class="{'d-block': validated && input.password.trim().length === 0}">
                  {{ $t('is_required') }}
                </div>
                <div class="invalid-feedback"
                     :class="{'d-block': validated && invalidLogin && input.password.trim().length > 0}">
                  {{ $t('invalid_login') }}
                </div>
              </div>
            </div>
            <button type="button"
                    class="btn btn-primary float-right"
                    v-on:click="login()">{{ $t('login') }}
            </button>
          </div>
        </div>
        <router-link :to="createAccountLink">{{ $t('create_account') }}</router-link>
        <router-link class="ml-4" to="/forgot-password">{{ $t('forgot_password') }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import UserRepository from '@/repository/UserRepository.js'

export default {
  name: 'Login',
  data() {
    return {
      validated: false,
      invalidLogin: false,
      input: {
        email: '',
        password: '',
      },
    }
  },
  computed: {
    createAccountLink: function() {
      if (this.$route.query.redirect) {
        return '/create-account?redirect=' + this.$route.query.redirect
      } else {
        return '/create-account'
      }
    },
  },
  methods: {
    login() {
      this.validated = true
      if (this.input.email !== '' && this.input.password !== '') {
        localStorage.removeItem('cookie:accepted');
        UserRepository.getInstance().authenticate(
            this.input.email,
            this.input.password,
        ).then((response) => {
          this.$store.commit('login', response.data)
          let redirect = this.$route.query.redirect
          if (redirect != null)
            window.location.href = redirect
          else
            window.location.href = '/'
        }).catch((error) => {
          this.invalidLogin = true
        })
      } else {
        this.invalidLogin = false
      }
    },
  },
  watch: {
    'input.email': function(oldValue, newValue) {
      if (this.validated && this.invalidLogin) {
        this.invalidLogin = false
      }
    },
    'input.password': function(oldValue, newValue) {
      if (this.validated && this.invalidLogin) {
        this.invalidLogin = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.login {
  margin-top: 60px;
  margin-bottom: 60px;
}
</style>
