import state from '../store'
import rx_utils from '../utils/rx_utils'

const listToTree = list => {
  const map = {}
  const roots = []

  list.forEach((v, i) => {
    map[v.id] = i
    list[i].children = []
  })

  list.forEach(v => (v.parent_id != 0
    ? list[map[v.parent_id]]?.categories.push(v)
    : roots.push(v)))

  return roots
}

const asTree = function() {
  let categories = rx_utils.rxToObject(state.getters.categories)
  categories.forEach((category) => {
    category['categories'] = []
  })
  return listToTree(categories)
}

export default { asTree: asTree }