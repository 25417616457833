import store from '../store'
import BaseRepository from './BaseRepository.js'

export default class UserRepository extends BaseRepository {

  async getUser() {
    this.get('/users/')
      .then((response) => {
        if (response && response.data)
          store.commit('updateUser', response.data)
      })
  }

  async authenticate(email, password) {
    return this.post('/users/authenticate', {
      email: email,
      password: password,
    })
  }

  async register(email, first_name, last_name, password, newsletters) {
    return this.post('/users/create', {
      email: email,
      first_name: first_name,
      last_name: last_name,
      password: password,
      newsletters: newsletters
    })
  }

  async forgotPassword(email) {
    return this.post('/users/forgot-password', {
      email: email,
    })
  }

  async resetPassword(password, token) {
    return this.post('/users/reset-password', {
      password: password,
      token: token,
    })
  }

  async updateAddress(address) {
    return this.put('/users/address', address)
  }

  async deleteAddress(addressId) {
    return this.delete('/users/address', {
      id: addressId
    })
  }

  async updateUserData(email, firstName, lastName, ic, dic, newsletters) {
    return this.post('/users/', {
      email: email,
      first_name: firstName,
      last_name: lastName,
      ic: ic,
      dic: dic,
      newsletters: newsletters
    })
  }
}