import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'
import Products from '@/views/Products'
import Cart from '@/views/Cart'
import Login from '@/views/Login'
import store from '../store/index.js'
import Product from '../views/Product'
import Manufacturers from '../views/Manufacturers'
import Categories from '../views/Categories'
import Search from '../views/Search'
import Account from '../views/Account'
import Orders from '../views/Orders'
import OrderAddress from '../views/order/OrderAddress'
import OrderState from '@/views/order/OrderState'
import Page from '@/views/Page'
import CreateAccount from '@/views/CreateAccount'
import AccountCreated from '@/views/AccountCreated'
import ResetPassword from '@/views/ResetPassword'
import ForgotPassword from '@/views/ForgotPassword'
import OrderShipping from '@/views/order/OrderShipping'
import OrderPayment from '@/views/order/OrderPayment'
import OrderOverview from '@/views/order/OrderOverview'
import AccountSettings from '@/views/AccountSettings'
import PageNotFound from '@/views/PageNotFound'
import Wishlist from '@/views/Wishlist'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: '404',
    component: PageNotFound
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: `/products/:slugs(.*)`,
    name: 'Products',
    component: Products,
  },
  {
    path: '/product/:id',
    name: 'Product',
    component: Product,
  },
  {
    path: '/page/:id',
    name: 'Page',
    component: Page,
  },
  {
    path: '/manufacturers',
    name: 'Manufacturers',
    component: Manufacturers,
  },
  {
    path: '/categories',
    name: 'Categories',
    component: Categories,
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: { requiresAuth: true },
  },
  {
    path: '/account-settings',
    name: 'AccountSettings',
    component: AccountSettings,
    meta: { requiresAuth: true },
  },
  {
    path: '/wishlist',
    name: 'Wishlist',
    component: Wishlist,
    meta: { requiresAuth: true },
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders,
    meta: { requiresAuth: true },
  },
  {
    path: '/orders/create/address',
    name: 'OrderCreate',
    component: OrderAddress,
  },
  {
    path: '/orders/create/shipping',
    name: 'OrderShipping',
    component: OrderShipping,
  },
  {
    path: '/orders/create/payment',
    name: 'OrderPayment',
    component: OrderPayment,
  },
  {
    path: '/orders/create/overview',
    name: 'OrderOverview',
    component: OrderOverview,
  },
  {
    path: '/orders/:hash/state',
    name: 'OrdersState',
    component: OrderState,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/create-account',
    name: 'CreateAccount',
    component: CreateAccount,
  },
  {
    path: '/account-created',
    name: 'AccountCreated',
    component: AccountCreated,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
  },
]

const router = new VueRouter({
  routes: routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    // Default scroll position will be 0, 0 unless overridden by a saved position
    const position = {
      x: 0,
      y: 0,
    }

    // Override default with saved position (if it exists)
    if (savedPosition) {
      position.x = savedPosition.x
      position.y = savedPosition.y
    }

    // Listen for scrollBeforeEnter event and set scroll position
    return new Promise(resolve => {
      this.app.$root.$once('scrollBeforeEnter', () => {
        resolve(position)
      })
    })
  },
})

router.beforeEach((to, from, next) => {
  window.analyticsTrackEvent('PageView', to.name);

  const isAuthenticated = store.getters.isAuthenticated
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router