import store from '../store'
import BaseRepository from './BaseRepository.js'

export default class PagesRepository extends BaseRepository {
  async getPages() {
    return this.get('/pages').then((response) => {
      store.commit('updatePages', response.data.items)
    })
  }

  async getPage(id) {
    return this.get(`/pages/item/?id=${id}`)
  }

  async getHomepage() {
    return this.get('/pages/homepage')
  }
}