import BaseRepository from './BaseRepository.js'
import Vue from 'vue';
import Log from '@/utils/logger'

export default class OrdersRepository extends BaseRepository {
  async getOrders() {
    return this.get('/orders')
  }

  async getOrder(id) {
    return this.get(`/orders/item/?id=${id}`)
  }

  async getOrderByHash(hash) {
    return this.get(`/orders/find?hash=${hash}`)
  }

  async createOrder(order) {
    this.axios.defaults.timeout = this.LONG_TIMEOUT
    order.order_items = order.order_items.map((it) => {
      return {
        product_id: it.id,
        price: it.price,
        count: it.count,
        product_imprint: it
      }
    })
    let request = this.post('/orders/create', { order: order })
    this.axios.defaults.timeout = this.DEFAULT_TIMEOUT
    return request
  }

  async updateOrder(order) {
    return this.post('/orders/update', { order: order })
  }
}