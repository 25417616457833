import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'
import Log from '@/utils/logger'
import 'bootstrap'
import App from './App.vue'
import AsyncComputed from 'vue-async-computed'
import VueConfirmDialog from 'vue-confirm-dialog'
import Notifications from 'vue-notification'
import VueCookies from 'vue-cookies'
import VueI18n from 'vue-i18n'
import { ENGLISH_TRANSLATIONS } from './translations/en'
import { CZECH_TRANSLATIONS } from './translations/cs'
import init from './utils/init'
import store from './store'
import router from './router'

//const devURL = 'eshop.harley-davidson-praha.cz';
const devURL = 'localhost:8000'

window.isDebug = () => process.env.NODE_ENV === 'development'
window.loggerTag = () => 'NetteEshop'
window.consentGiven = () => localStorage.getItem('cookie:accepted') === 'true'
window.analyticsTrackEvent = function(event, data) {
  if (window.consentGiven()) {
    if (window.isDebug()) {
      Log.d(`analyticsTrackEvent: ${event}`, data)
    }
    // facebook pixel tracking (script located in `public/index.html`)
    window.fbPixel('track', event, data);
  }
}

window.host = function() {
  if (window.isDebug()) {
    Log.d('devURL', devURL);
    return devURL
  } else {
    return Object.assign(document.createElement('a'),
      { href: window.location.href }).host
  }
}

function setupSentry() {
  Sentry.init({
    dsn: 'https://58cc12158922493b9a966d1ece01b944@o196854.ingest.sentry.io/5444922',
    integrations: [
      new VueIntegration({
        Vue,
        tracing: true,
      }),
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1,
  })
}

if (!window.isDebug()) {
  //setupSentry()
}

//mixins
require('./mixins/user')
require('./mixins/cart')
require('./mixins/products')
require('./mixins/categories')
require('./mixins/manufacturers')
require('./mixins/order')
require('./mixins/notify')
require('./mixins/utils')
require('./mixins/locale')
require('./mixins/pages')
require('./mixins/promo')
require('./mixins/delivery_types')

Vue.use(VueCookies)
Vue.use(VueI18n)
Vue.use(AsyncComputed)
Vue.use(Notifications)
Vue.use(VueConfirmDialog)
Vue.use(require('vue-moment'))
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

init(store).then(() => {
  Vue.config.productionTip = false

  const TRANSLATIONS = {
    en: ENGLISH_TRANSLATIONS,
    cs: CZECH_TRANSLATIONS,
  }
  const i18n = new VueI18n({
    locale: 'cs',
    messages: TRANSLATIONS,
  })

  window.app = new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app')
}).catch((e) => {
  Log.e('init', e)
  if (window.isDebug()) {
    alert(`Init error: ${e}`)
  }
})

