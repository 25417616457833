<template>
  <div class="products">
    <div class="header-background container-fluid"
         v-if="category && category.images != null && category.images.length > 0"
         :class="{'padded': category.top_description}"
         :style="`background-image: url('${categoryBgImage}');`">
      <div class="top-description" v-if="category.top_description">
        <div class="container">
          <BackendTranslatedText
              :key="category.id"
              :default-value="category.top_description"
              :translation-key="'top_description'"
              :translations="category.translations"/>
        </div>
      </div>
      <div class="container">
        <div class="hero-text"
             :class="{'float-left': category.hero_text_position == 'top_left',
                      'float-right': category.hero_text_position == 'top_right',
                      'float-left bottom': category.hero_text_position == 'bottom_left',
                      'float-right bottom': category.hero_text_position == 'bottom_right',
                      'center': category.hero_text_position == 'center'}"
             v-if="category.title && category.long_description">
          <b class="big" v-if="category.title">
            <BackendTranslatedText
              :key="category.id"
              :default-value="category.title"
              :translation-key="'title'"
              :translations="category.translations"/>
          </b>
          <p v-if="category.long_description">
            <BackendTranslatedText
                :key="category.id"
                :default-value="category.long_description"
                :translation-key="'long_description'"
                :translations="category.translations"/>
          </p>
        </div>
      </div>
    </div>
    <Breadcrumbs
        v-else-if="category"
        :prefix="$t('products')"
        :navigate="selectCategory"
        :path="breadcrumbsFromCategory(category.id)"/>

    <div class="container mt-3 mb-3"
         v-if="category.images == null || category.images.length === 0">
      <div class="row" v-if="Strings.stripHTML(category.top_description).length > 0">
        <div class="col">
          <BackendTranslatedText
              :key="category.id"
              :default-value="category.top_description"
              :translation-key="'top_description'"
              :translations="category.translations"/>
        </div>
      </div>
      <div class="row" v-if="Strings.stripHTML(category.long_description).length > 0">
        <div class="col">
          <BackendTranslatedText
              :key="category.id"
              :default-value="category.long_description"
              :translation-key="'long_description'"
              :translations="category.translations"/>
        </div>
      </div>
    </div>

    <div class="container mt-3" v-if="Strings.stripHTML(category.permanent_description).length > 0">
      <div class="row">
        <div class="col">
          <BackendTranslatedText
              :key="category.id"
              :default-value="category.permanent_description"
              :translation-key="'permanent_description'"
              :translations="category.translations"/>
        </div>
      </div>
    </div>

    <div v-if="!isRootCategory" class="container-full filters"
         :ref="'filters'"
         v-bind:class="{'affix': filtersAffix}">
      <div class="container">
        <div class="row">
          <div class="col">
            <SubCategories v-if="category" :category="category"
                            @changed="selectCategory"
                            class="md-w100p"/>
            <ProductsFilterHorizontal
                v-if="products.length > 0"
                :key="category.id"
                class="md-w100p"
                @change="filterChange"
                @cancel="cancelFilter"
                :price-low="price.low"
                :price-high="price.high"
                :price-first-value="price.firstValue"
                :price-second-value="price.secondValue"
                :manufacturer-id="manufacturerId"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="products-grid-wrapper">
      <div v-if="isRootCategory"
           class="container categories-horizontal text-center pt-4 pb-4">
        <template v-if="categories">
          <span class="category"
                v-for="item in categories.filter(c => c.parent_id == category.id)"
                :key="item.id">
            <a v-on:click="selectCategory(item, item.parent_id)">
              <BackendTranslatedText
                  :key="item.id"
                  :default-value="item.title"
                  :translation-key="'title'"
                  :translations="item.translations"/>
            </a>
          </span>
        </template>
      </div>
      <div class="container">
        <ProductsGrid :page="page" :pages="pages" :products="products"
                      :sort="sort" :sortChange="sortChange"
                      :select-page="selectPage" :count="count"/>
        <hr>
        <RecentlyViewed :key="$route.params.category" class="pb-4"/>
      </div>
    </div>
  </div>
</template>

<script>
import Log from '../utils/logger'
import ProductsFilterHorizontal from '../components/ProductsFilterHorizontal'
import Breadcrumbs from '../components/Breadcrumbs'
import ProductsGrid from '../components/ProductsGrid'
import SubCategories from '@/components/SubCategories'
import BackendTranslatedText from '@/components/BackendTranslatedText'
import RecentlyViewed from '@/components/RecentlyViewed'
import EventBus from '@/utils/eventbus'
import Strings from '@/utils/strings'

export default {
  name: 'Products',
  components: {
    BackendTranslatedText,
    SubCategories,
    Breadcrumbs,
    ProductsFilterHorizontal,
    ProductsGrid,
    RecentlyViewed,
  },
  data: function() {
    return {
      pageLoading: false,
      products: [],
      count: 0,
      page: 0,
      pages: 0,
      perPage: 20,
      category: null,
      sort: null,
      manufacturerId: null,
      price: {
        low: 0,
        high: null,
        firstValue: 0,
        secondValue: null,
      },
      filtersAffix: false,
      defaultSort: 'last_modified',
    }
  },
  methods: {
    selectPage: function(page) {
      this.products = []
      this.$router.push({
        path: this.$route.path,
        query: Object.assign({},
            this.$route.query,
            {
              page: page,
              per_page: this.$data.perPage,
            },
        ),
      }).then(() => {
        this.loadPage()
      })
    },
    selectCategory: function(category) {
      this.$router
          .push({
            path: '/products/' + this.generateCategoryLink(category),
            query: { page: 1, price_low: null, price_high: null, manufacturer: null },
          })
          .then(() => {
            this.loadPage()
          })
    },
    loadPage: function() {
      if (this.pageLoading) return
      this.pageLoading = true
      Log.d('loadPage')
      let query = this.$route.query
      let page = parseInt(query.page)
      let manufacturerId = parseInt(query.manufacturer) || null
      let category = this.getCategoryByUrl(this.$route.params.slugs)
      let categoryId = category.id
      let sort = query.sort ?? this.defaultSort
      this.getProducts(
          page - 1,
          query.per_page,
          categoryId,
          query.price_low,
          query.price_high,
          manufacturerId,
          sort,
      ).then((response) => {
        this.products = response.data.items
        this.count = response.data.count
        this.page = page
        this.manufacturerId = manufacturerId
        this.sort = sort
        this.pages = (parseInt(response.data.count / response.data.per_page) + 1)
        this.price.low = 0
        this.price.high = response.data.highest_price
        this.price.firstValue = parseInt(query.price_low) || 0
        this.price.secondValue = parseInt(query.price_high) || response.data.highest_price
        this.category = this.categories.find(category => category.id == categoryId)
        this.pageLoading = false
        this.scrollToTop()
      }).catch((error) => {
        this.pageLoading = false
        Log.e(error)
      })
    },
    filterChange: function(values) {
      this.price = values.price
      this.manufacturerId = values.manufacturerId
      this.$router.push({
        query: Object.assign({},
            this.$route.query,
            {
              page: 1,
              manufacturer: values?.manufacturerId || this?.manufacturerId,
              price_low: values?.price?.firstValue || this?.price?.firstValue,
              price_high: values?.price?.secondValue || this?.price?.secondValue,
            }),
      }).then(() => {
        this.loadPage()
      })
    },
    sortChange: function(value) {
      if (value !== this.sort) {
        this.sort = value
      } else {
        this.sort = null
      }
      this.$router.push({
        query: Object.assign({},
            this.$route.query,
            {
              page: 1,
              sort: this.sort,
            }),
      }).then(() => {
        this.loadPage()
      })
    },
    cancelFilter: function() {
      this.$router.push({
        path: '/products/' + this.generateCategoryLink(this.category),
        query: { page: 1 },
      })
          .then(() => {
            this.loadPage()
          })
    },
    affixFilters: function() {
      window.onscroll = () => {
        const scrollTop = window.pageYOffset
        this.filtersAffix = scrollTop > 204
        if (this.filtersAffix) {
          if (this.filtersHeight() != null)
            document.body.style.margin = this.filtersHeight() + 'px 0px 0px 0px'
        } else {
          document.body.style.margin = '0'
        }
      }
    },
    filtersHeight: function() {
      return this.$refs['filters']?.clientHeight
    },
  },
  computed: {
    Strings() {
      return Strings
    },
    categoryBgImage: function() {
      return window.location.protocol + '//' + this.host + '/data/' +
          this.category.images[0].identifier
    },
    isRootCategory: function() {
      return this.category?.parent_id === 0
    },
  },
  watch: {
    pageLoading: function(newVal, oldVal) {
      this.loading(newVal)
    },
  },
  mounted() {
    this.loadPage()
    this.affixFilters()
    let self = this
    EventBus.$on('selectCategory', function(category) {
      self.selectCategory(category)
    })
  },
  beforeDestroy() {
    window.onscroll = null
    document.body.style.margin = '0'
  },
}
</script>

<style lang="scss">
@import "../theme/theme";

.products {
  .dropdown {
    button {
      font-family: $font-family-base;
      font-size: 12px;
    }
  }

  .filters {
    padding: 0;

    &.affix {
      top: 0;
      position: fixed;
      width: 100vw;
      background: white;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
      z-index: 500;
    }
  }

  .products-grid-wrapper {
    background-color: $hd-gray;

    @include media-breakpoint-down(md) {
      padding-top: 40px;
    }

    .category {
      a {
        display: inline-block;
      }
    }
  }
}
</style>
