import Vue from 'vue'
import store from '../store'

Vue.mixin({
  methods: {
    scrollToTop: function() {
      window.scrollTo(0, 0)
    },
    isDebug: function() {
      return window.isDebug()
    },
    loading: function(value, message = null) {
      store.commit('loading', value)
      store.commit('loadingMessage', message)
    },
  },
  computed: {
    host: function() {
      if (window.isDebug()) {
        return 'nette-eshop.loc'
      } else {
        return Object.assign(document.createElement('a'),
          { href: window.location.href }).host
      }
    },
    currency: function() {
      return store.getters.currency
    },
    isLoading: function() {
      return store.getters.isLoading
    },
  },
})