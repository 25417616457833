<template>
  <div class="product card mb-4"
       @mouseover="hover = true"
       @mouseleave="hover = false">
    <div class="hover-bg"></div>
    <div class="fg">
      <a @click.left="openProduct(product.id)"
         @click.middle="openProduct(product.id, true)">
        <div class="image text-center">
          <BackendImage
              class="img-fluid"
              :class="{'d-none': hover && product.images.length > 1}"
              v-if="product.images != null && product.images.length > 0"
              :identifier="product.images[0].identifier"
              :alt="product.title"
              :size="250"/>
          <BackendImage
              class="img-fluid"
              :class="{'d-none': !hover && product.images.length > 1}"
              v-if="product.images != null && product.images.length > 1"
              :identifier="product.images[1].identifier"
              :alt="product.title"
              :size="250"/>
          <BackendImage
              class="img-fluid"
              v-if="product.images == null || product.images.length === 0"
              :identifier="null"
              :alt="product.title"
              :size="250"/>
        </div>
        <h2 class="title">
          <backend-translated-text :default-value="product.title"
                                   :translations="product.translations"
                                   :translation-key="'title'"/>
        </h2>
      </a>
      <ProductLabels :product="product"/>
      <div class="prices">
        <div v-if="product.discount > 0" class="price before-discount">
          <money-format :value="processPrice(product,null, false)"
                        :locale='currentLocale'
                        :currency-code='currency'
                        :hide-subunits='true'/>
        </div>
        <div class="price">
          <money-format :value="processPrice(product)"
                        :locale='currentLocale'
                        :currency-code='currency'
                        :hide-subunits='true'/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackendTranslatedText from '@/components/BackendTranslatedText'
import BackendImage from './BackendImage'
import MoneyFormat from 'vue-money-format'
import ProductLabels from '@/components/ProductLabels'

export default {
  name: 'Product',
  props: ['product'],
  components: {
    BackendTranslatedText,
    ProductLabels,
    BackendImage,
    'money-format': MoneyFormat,
  },
  data: function() {
    return {
      hover: false,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "src/theme/theme";

.product {
  border: none;
  background: none;
  height: 100%;
  padding-bottom: 16px;

  .hover-bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background: white;
    pointer-events: none;
    z-index: 1;
    transition-duration: 200ms;
    transition-delay: 500ms;
  }

  .fg {
    z-index: 2;
  }

  &:hover {
    .hover-bg {
      height: calc(100% - 8px);
      transition-duration: 200ms;
      transition-delay: 0ms;
    }
  }

  .labels {
    top: 8px;
  }

  .image {
    background-color: white;
    padding: 16px;
    height: 300px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .title, .prices {
    padding-top: 4px;
    margin-left: 8px;
    margin-right: 8px;
  }

  a {
    cursor: pointer;

    h2 {
      margin-top: 8px;
      font-size: 16px;
      text-transform: uppercase;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: $font-family-base;
      letter-spacing: 0.1px;
      font-weight: bold;
    }

    .description {
      text-decoration: none;
      text-overflow: ellipsis;
      overflow: hidden;
      max-height: 52px;
      font-size: 12px;
    }
  }

  .price {
    font-size: 14px;
    line-height: 12px;

    .money_format {
      display: inline-block;
    }

    &.before-discount {
      font-size: 12px;
      line-height: 25px;
      opacity: 0.7;

      .money_format {
        text-decoration: line-through $red;
      }
    }
  }
}
</style>
