<template>
  <div class="home">
    <Banner v-if="banner" :banner="banner"/>
    <div class="container pages pt-4 pb-4" v-for="page in pages" :key="page.id">
      <div class="mt-4 mb-4" v-html="page.page_data['gjs-html']"></div>
    </div>
  </div>
</template>

<script>
import PagesRepository from '@/repository/PagesRepository'
import Banner from '@/components/Banner'
import * as _ from 'lodash'

export default {
  name: 'Home',
  components: {
    Banner,
  },
  data: function() {
    return {
      pages: null,
      banners: null,
    }
  },
  computed: {
    banner: function() {
      let bannersForCurrentLang = _.filter(this.$store.getters.banners,
          (banner) => banner.lang == this.currentLocale)
      return _.sample(bannersForCurrentLang)
    },
  },
  methods: {
    loadData: function() {
      PagesRepository.getInstance().getHomepage()
          .then((response) => {
            this.pages = response.data.items
            response.data.items.forEach((it) => {
              document.head.insertAdjacentHTML('beforeend',
                  `<style>${it.page_data['gjs-css']}</style>`)
            })
          })
          .catch((error) => {
            this.notifyError(error.message)
          })
    },
  },
  mounted: function() {
    this.loadData()
  },
  watch: {
    'currentLocale': function() {
      this.loadData()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../theme/theme";

.home {
  background-color: $hd-gray;

  .page {
    margin-top: 50px;
  }
}
</style>