<template>
  <div class="order billing container">
    <div class="row">
      <div class="col" v-if="order">
        <div class="card">
          <div class="card-header">
            <h1>
              {{ $t('order') }}: {{ order.id }} -
              <FormattedDate :date="order.order_date"/>
            </h1>
          </div>
          <div class="card-body">
            <div class="card">
              <div class="card-header">
                <h2>{{ $t('order_state') }}</h2>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-6">
                    {{ $t(order.state) }}
                  </div>
                  <div class="col-sm-6">
                    <a v-if="showPayButton()" v-on:click="payOrder()"
                       class="btn btn-primary float-right">
                      {{ $t('pay_order') }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mt-4">
              <div class="card-header">
                <h2>
                  {{ $t('order_items') }}
                </h2>
              </div>
              <div class="card-body">
                <template v-for="(item, index) in order.order_items">
                  <CartItem
                      :key="index"
                      :item="item.product_imprint"
                      :is-cart="false"
                      :item-count="item.count"
                      :promo-code-ids="item.promo_code_ids"
                      @resentPromoCodesClicked="resentPromoCodesClicked"/>
                </template>
                <CartItem
                    v-if="bonusItem != null"
                    :item="bonusItem"
                    :is-cart="false"
                    :disable-links="true"
                    :item-count="1"/>
                <CartItem
                    :item="shippingItem"
                    :free-shipping-prop="order.free_shipping"
                    :is-cart="false"
                    :disable-links="true"
                    :item-count="1"/>
                <CartItem
                    v-if="promoItem != null"
                    :item="promoItem"
                    :is-cart="false"
                    :disable-links="true"
                    :item-count="1"/>
                <CartItem
                    :item="paymentItem"
                    :is-cart="false"
                    :disable-links="true"
                    :item-count="1"/>
                <CartTotal :count="count" :price="price" :is-cart="false"/>
              </div>
            </div>
            <PacketaSelectedPoint :packetaSelectedPoint="packetaSelectedPoint"
                                  class="mt-4"
                                  v-if="shippingType.delivery_type == deliveryTypes.zasilkovna"/>
            <div class="card mt-4">
              <div class="card-header">
                <h2>{{ $t('address') }}</h2>
              </div>
              <div class="card-body">
                <div class="order-address">
                  <div class="row">
                    <div class="col-md-6 shipping" v-if="shippingAddressAvailable()">
                      <AddressListing :address="order.shipping_address"/>
                    </div>
                    <div class="col-md-6 billing" v-if="billingAddressAvailable()">
                      <AddressListing :address="order.billing_address"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrdersRepository from '@/repository/OrdersRepository'
import CartItem from '@/components/CartItem'
import CartTotal from '@/components/CartTotal'
import AddressListing from '@/components/AddressListing'
import PaymentRepository from '@/repository/PaymentRepository'
import FormattedDate from '@/components/FormattedDate'
import PromoCodesRepository from '@/repository/PromoCodesRepository'
import Log from '@/utils/logger'
import PacketaSelectedPoint from '@/components/PacketaSelectedPoint.vue'

export default {
  name: 'OrderState',
  components: {
    PacketaSelectedPoint,
    AddressListing, CartItem, CartTotal, FormattedDate,
  },
  data() {
    return {
      order: null,
    }
  },
  methods: {
    resentPromoCodesClicked: function(promoCodesIds) {
      this.loading(true)
      let email = this.order.shipping_address.email
      Promise.all(promoCodesIds.map((promoCodeId) =>
          PromoCodesRepository.getInstance().resentEmail(promoCodeId, email)),
      ).then(() => {
        this.loading(false)
      })
    },
    shippingAddressAvailable() {
      return this.order.shipping_address
    },
    billingAddressAvailable() {
      return this.order.billing_address
    },
    payOrder() {
      this.loading(true, this.$t('waiting_for_payment_gateway'))
      PaymentRepository.getInstance().createPayment(this.order.hash).then((response) => {
        window.location.href = response.data.payment_url
      }).catch((e) => {
        this.loading(false)
        this.notifySomethingWrongHappened()
      })
    },
    showPayButton() {
      if (this.paymentMethod == 'online' && this.order.price > 0)
        return this.order.state == 'pending'
      else
        return false
    },
  },
  computed: {
    count: function() {
      let count = 0
      this.order.order_items.forEach((item) => {
        count += item.count
      })
      return count
    },
    price: function() {
      let price = 0
      this.order.order_items.forEach((item) => {
        price += item.count *
            this.processPrice(item.product_imprint)
      })
      price += this.order.shipping_price
      price += this.order.payment_price
      price -= this.order.bonus_value
      price -= this.order.promo_value
      if (price < 0) return 0
      else return price
    },
    packetaSelectedPoint: function() {
      console.log(JSON.parse(this.order.additional_data))
      return JSON.parse(this.order.additional_data).packeta
    },
    shippingType: function() {
      return this.getShippingTypes()
          .find((it) => it.id == this.order.shipping_type_id)
    },
    paymentType: function() {
      return this.getPaymentTypes()
          .find((it) => it.id == this.order.payment_type_id)
    },
    paymentMethod: function() {
      return this.getPaymentTypes()
          .find((it) => it.id == this.order.payment_type_id).method
    },
    shippingItem: function() {
      let price = 0
      if (!this.order.free_shipping) {
        price = this.order.shipping_price
      }
      let description = this.getShippingTypes()
          .find((it) => it.id === this.order.shipping_type_id).title
      return {
        title: this.$t('shipping'),
        id: description,
        count: 1,
        price: price,
        price_with_vat: price,
        price_before_free_shipping: this.order.shipping_price,
      }
    },
    paymentItem: function() {
      let description = this.getPaymentTypes()
          .find((it) => it.id === this.order.payment_type_id).title
      return {
        title: this.$t('payment'),
        id: description,
        count: 1,
        price: this.order.payment_price,
        price_with_vat: this.order.payment_price,
      }
    },
    bonusItem: function() {
      if (this.order.bonus_value > 0)
        return {
          title: this.$t('bonus_rate'),
          id: '',
          count: 1,
          price: -this.order.bonus_value,
          price_with_vat: -this.order.bonus_value,
        }
      else return null
    },
    promoItem: function() {
      if (this.order.promo_code != null && this.order.promo_code !== '') {
        return {
          title: this.$t('promo_code'),
          id: this.order.promo_code,
          count: 1,
          price: -this.order.promo_value,
          price_with_vat: -this.order.promo_value,
        }
      } else return null
    },
  },
  mounted() {
    this.loading(true)
    OrdersRepository.getInstance().getOrderByHash(this.$route.params.hash)
        .then(response => {
          let order = response.data
          order.order_items.map((item) => {
            item.title = item.product_imprint.title
          })
          this.order = order
          this.loading(false)
        })
  },
}
</script>