<template>
  <div class="manufacturers">
    <h1>{{ $t('manufacturers') }}</h1>
    <ul v-if="manufacturers">
      <li v-for="manufacturer in manufacturers"
          v-bind:key="manufacturer.id">
        <router-link
            :to="{path: '/products', query: { manufacturer: manufacturer.id }}">
          <h2>{{ manufacturer.title }}</h2>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Manufacturers',
}
</script>

<style lang="less" scoped>

</style>
