import BaseRepository from '@/repository/BaseRepository'
import store from '../store'

export default class WishlistRepository extends BaseRepository {
  async getUsersWishlist() {
    return this.get('/wishlist/list')
      .then((response) => {
        store.commit('updateWishlist', response.data.wishlist);
      })
  }

  async addToWishlist(product) {
    return this.post('/wishlist/add', {
      product_id: product.id
    }).then((response) => {
      store.commit('updateWishlist', response.data.wishlist);
    })
  }

  async removeFromWishlist(product) {
    return this.delete('/wishlist/remove', {
      product_id: product.id
    }).then((response) => {
      store.commit('updateWishlist', response.data.wishlist);
    })
  }
}